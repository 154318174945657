import { isMobile } from "react-device-detect"
import {
    ACCOUNT_PAGE_URL,
    ANALYTICS_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    HOME_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    LINKS_PAGE_URL,
    LOGIN_INFLUENCER_PAGE_URL,
    LOGIN_PAGE_URL,
    LOGIN_STORE_PAGE_URL,
    MAGIC_STORE_PAGE_URL,
    MULTILINKS_PAGE_URL,
    PRICING_PLANS_PAGE_URL,
    PRIVACY_POLICY_PAGE_URL,
    REGISTER_STORE_PAGE_URL,
    SHOPBOARD_PAGE_URL,
    SHORTS_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    TERMS_PAGE_URL,
    TRENDS_PAGE_URL,
} from "./routes.constants"
import { BiLink } from "react-icons/bi"
import ShortsImage from "embeddedImages/ShortsImage"
import { addTimeToDate, calculateDateRange } from "utils/dateUtils"
import ProductImageStyleDisplayImage from 'assets/woman_display_image.png'
import InsightsImage from "embeddedImages/InsightsImage"
import ShoppingBagImage1 from "embeddedImages/ShoppingBagImage1"
import ShoppingBagImage2 from "embeddedImages/ShoppingBagImage2"
import ShoppingBagImage3 from "embeddedImages/ShoppingBagImage3"
import { LiaShoppingBagSolid } from "react-icons/lia"

export const REQUEST_TIMEOUT_MILLISECONDS = 10000
export const IMAGE_UPLOAD_REQUEST_TIMEOUT_MILLISECONDS = 60000
export const TOP_BAR_HEIGHT_PX = 50
export const APPLICATION_RECOVERY_TIMEOUT_MILISECONDS = 20000
export const ANIMATIED_STRING_LOADER_ANIMATION_DURATION_SECONDS = 0.25
export const APPLICATION_READY_TIMEOUT_MILISECONDS = 500
export const MAX_ITEMS_SEARCH_RESULT_NUMBER = 50

const APPLICATION_RESOURCES_DOMAIN = 'https://cdn.paylink.cloudfront.payli.in/application_resources'
export const PAYLINK_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-logo.webp"
export const PAYLINK_SMALL_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-small-logo.webp"
export const PAYLINK_SMALL_LOGO_WHITE_URL = APPLICATION_RESOURCES_DOMAIN + "/paylink-small-logo-white.webp"
export const NO_IMAGE_PLACEHOLDER_URL = APPLICATION_RESOURCES_DOMAIN + "/no_image.webp"
export const SHOPIFY_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/shopify-logo.svg"
export const SHORTS_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + "/shorts_icon.svg"
export const NO_IMAGE_GLOBE_PLACEHOLDER_URL = APPLICATION_RESOURCES_DOMAIN + "/www-globe.png"

export const WELCOME_PAGE_FIRST_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage1.png'
export const WELCOME_PAGE_SECOND_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage2.png'
export const WELCOME_PAGE_THIRD_SECTION_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/WelcomePage3.jpg'

export const HOME_PAGE_TODO_TASK_LOGO_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-logo.png'
export const HOME_PAGE_TODO_TASK_ANALYTICS_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-analytic.png'
export const HOME_PAGE_TODO_TASK_INSTANT_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-instant.png'
export const HOME_PAGE_TODO_TASK_SHIPPING_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-shipping.png'
export const HOME_PAGE_TODO_TASK_UTM_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/todo-utm.png'

export const SHOPBOARD_PAGE_IMAGE = APPLICATION_RESOURCES_DOMAIN + '/Shopboard.png'

export const INSTAGRAM_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + '/instagram_color.svg'
export const TIKTOK_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + '/tik-tok_color.svg'
export const PINTEREST_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + '/pinterest_color.svg'

export const INSTAGRAM_BLACK_WHITE_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + '/instagram_black.svg'
export const TIKTOK_BLACK_WHITE_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + '/tik-tok_black.svg'
export const PINTEREST_BLACK_WHITE_LOGO_URL = APPLICATION_RESOURCES_DOMAIN + '/pinterest_black.svg'

export const FREE_MODE_DIALOG_IMAGE_URL = APPLICATION_RESOURCES_DOMAIN + '/paylinkshop-exmple.png'

export const ADMIN_PAGE_MENU_OPTION_HEIGHT_PX = 50
export const PAYDIN_DIALOG_CONTENT_PADDING_PX = 15
export const EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS = 400
export const SIGN_UP_PAGE_VALIDATE_FIELD_REQUEST_SEND_DELAY_MILLISECONDS = 400
export const SIGN_UP_PAGE_USERNAME_INPUT_NAME = 'username'
export const SIGN_UP_PAGE_EMAIL_INPUT_NAME = 'email'
export const SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX = ".myshopify.com"
export const PAGE_DEFAULT_MAX_WIDTH = 750
export const ADMIN_PAGE_LINKS_MAX_WIDTH = 1000
export const LINK_EDIT_PREVIEW_PAGE_MAX_WIDTH = 1100
export const LINK_PAGES_MAX_WIDTH = 1000
export const MULTILINK_PAGES_MAX_WIDTH = 1000
export const ANALYTICS_PAGE_MAX_WIDTH = 900
export const ACCOUNT_PAGE_MAX_WIDTH = 750
export const PRICING_PLANS_PAGE_MAX_WIDTH = 1230
export const TRENDS_PAGE_MAX_WIDTH = 1500
export const SHOPIFY_HELP_PAGE_MAX_WIDTH = 1200
export const CREATE_INFLUENCER_PAGE_MAX_WIDTH = 630
export const PREVIEW_SHORT_PAGE_MAX_WIDTH = 700
export const CREATE_NEW_PASSWORD_PAGE_MAX_WIDTH = 500
export const USER_PASSWORD_MIN_LENGTH = 6
export const PAYLINK_LOGO_MAX_WIDTH_PX = 120
export const EDIT_LINK_PAGE_PRODUCT_SEARCH_QUERY_MIN_LENGTH = 2
export const NO_AUTH_PAGES = [
    LOGIN_STORE_PAGE_URL,
    LOGIN_PAGE_URL,
    LOGIN_INFLUENCER_PAGE_URL,
    REGISTER_STORE_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL
]
export const PADDED_PAGES = [
    LOGIN_PAGE_URL,
    LOGIN_STORE_PAGE_URL,
    REGISTER_STORE_PAGE_URL,
    LOGIN_INFLUENCER_PAGE_URL,
    SIGN_UP_PAGE_URL,
    SIGN_UP_REQUEST_PAGE_URL,
    FORGOT_PASSWORD_PAGE_URL,
    INFLUENCER_FORGOT_PASSWORD_PAGE_URL,
    CREATE_NEW_PASSWORD_PAGE_URL,
    // This is nessecary for matching the url that's been redirected from server with and without '/'.
    // FIX - define all URLs as regex for optimizations
    CREATE_NEW_PASSWORD_PAGE_URL + '/',
    HOME_PAGE_URL
]
export const UNLIMITED_WIDTH_PAGES = [
    PRIVACY_POLICY_PAGE_URL,
    TERMS_PAGE_URL,
    MAGIC_STORE_PAGE_URL
]
export const ACCOUNT_PAGE_DANGER_ZONE_SECTION_PADDING_TOP_PX = 20
export const ACCOUNT_PAGE_DANGER_ZONE_SECTION_PADDING_BOTTON_PX = 20

export const ACCOUNT_PAGE_SHOP_WEBSITE_INPUT_NAME = 'shop-website'
export const ACCOUNT_PAGE_SHOP_SHIPPING_AND_RETURNS_INPUT_NAME = 'shipping-and-returns'
export const ACCOUNT_PAGE_NAME_INPUT_NAME = 'name'
export const ACCOUNT_PAGE_USERNAME_INPUT_NAME = 'username'
export const ACCOUNT_PAGE_EMAIL_INPUT_NAME = 'email'
export const ACCOUNT_PAGE_FACEBOOK_PIXEL_ID_INPUT_NAME = 'facebook-pixel-id'
export const ACCOUNT_PAGE_GOOGLE_ANALYTICS_ID_INPUT_NAME = 'google-analytics-id'
export const ACCOUNT_PAGE_URL_PARAMS_INPUT_NAME = 'url-params'

export const PRICING_PLAN_DATA = {
    FREE: {
        name: 'free',
        presentedName: 'lite',
        color: '#cccccc',
        price: '0',
        pricingPeriod: 'month',
        ordinal: 1,
        features: [
            {
                id: 'Forever Free',
                content: 'Forever Free'
            },
            {
                id: 'Shorts',
                content: 'Trackable Shorts links'
            },
            {
                id: 'Shopboard',
                content: 'Shop the Feed'
            },
            {
                id: 'Data sync',
                content: 'Sync every 24 hours'
            }
        ],
        description: "You will not lose your free trial when upgrading"
    },
    STARTER: {
        name: 'starter',
        presentedName: 'core',
        color: '#ffdfb0',
        price: 49.9,
        pricingPeriod: 'month',
        ordinal: 2,
        features: [
            {
                id: '30 days free trial',
                content: '30 days free trial'
            },
            {
                id: 'Everything in Lite',
                content: 'Everything in Lite'
            },
            {
                id: 'Instant-Store links',
                content: 'Instant-Store links'
            },
            {
                id: 'Advanced analytics',
                content: 'Advanced analytics'
            },
            {
                id: 'Customization',
                content: 'Customization'
            },
            {
                id: 'Limited Influencers platform',
                content: 'Limited Influencers platform'
            },
            {
                id: 'Data sync',
                content: 'Live inventory sync'
            }
        ],
        description: '1 USD transaction fee\non smart multi-products links'
    },
    PRO: {
        name: 'pro',
        presentedName: 'pro',
        color: '#a3d6f7',
        price: 199.9,
        pricingPeriod: 'month',
        ordinal: 3,
        features: [
            {
                id: '30 days free trial',
                content: '30 days free trial'
            },
            {
                id: 'Everything in Core',
                content: 'Everything in Core'
            },
            {
                id: 'Influencers platform',
                content: 'Influencers platform'
            },
            {
                id: 'Personalized Onboarding',
                content: 'Personalized Onboarding'
            },
            {
                id: 'Priority support',
                content: 'Priority support'
            },
            {
                id: 'Phone Support',
                content: 'Phone Support'
            }
        ],
        description: '0.75 USD transaction fee\non smart multi-products links'
    },
    ENTERPRISE: {
        name: 'enterprise',
        color: '#fcbdbd',
        price: null,
        pricingPeriod: 'month',
        ordinal: 4,
        features: [],
        subtitle: "Let's talk"
    }
}

export const PRICING_PLANS_ENTERPRISE_PLAN_CONTACT_US_EMAIL = 'mailto:sales@paylinkshop.com'

export const UNAVAILABLE_PLANS = []
export const CONTACT_US_PLANS = [PRICING_PLAN_DATA.ENTERPRISE.name]

export const BOXED_MESSAGE_TYPES = {
    ERROR: 'error',
    INFO: 'info'
}

export const SHOPIFY_CONNECT_NAVIGATE_TO_ADMIN_PAGE_DELAY = 2000

export const PAYDIN_MENU_DEFAULT_WIDTH_PX = 250
export const PAYDIN_MENU_OPENING_DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right'
}

export const DASHBOARD_TOPBAR_USER_MENU_BORDER_RADIUS_PX = 15
export const DASHBOARD_TOPBAR_USER_MENU_WIDTH_PX = 350

export const PAYDIN_DRAWER_DIRECTION = {
    TOP: 'top',
    RIGHT: 'right',
    LEFT: 'left',
    BOTTOM: 'bottom',
}

export const LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX = 15

const PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS = 250
export const CREATE_LINK_REDIRECT_TO_PAGE_DELAY = 250
export const SEPERATE_GO_BACK_FOR_SAFARY_DELAY = 100
export const LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY = PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS
export const LINK_ITEM_MOBILE_OPTIONS_DRAWER_PADDING_PX = 20
export const DATE_PICKER_MOBILE_DRAWER_PADDING_PX = 10
export const MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY = PAYDIN_DRAWER_TRANSLATION_ANIMATION_DURATION_MILLISECONDS
export const LINK_ID_ROUTE_PARAMETER_INDEX = 3
export const INFLUENCER_USERNAME_ROUTE_PARAMETER_INDEX = 3

export const VIEW_MODES = {
    PREVIEW: 'preview',
    EDIT: 'edit',
    CREATE: 'create'
}

export const ADD_PRODUCTS_DIALOG_PRODUCTS_LIST_ITEM_HEIGHT_PX = 190
export const ADD_PRODUCTS_DIALOG_FETCH_NEXT_PRODUCTS_COLUMN_THRESHOLD_COUNT = 1
export const ADD_PRODUCTS_DIALOG_PRODUCTS_IN_COLUMNS = isMobile ? 3 : 7

export const CREATE_LINK_PHASES = {
    ADD_IMAGE_PHASE: 0,
    ADD_LINK_PROPERTIES_PHASE: 1
}

export const DASHBOARD_LAYOUT_PAGES_WITH_DASHBOARD_TOPBAR = [
    LINKS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    SHORTS_PAGE_URL,
    HOME_PAGE_URL,
    PRICING_PLANS_PAGE_URL,
    MULTILINKS_PAGE_URL,
]

export const LINK_ITEM_HEIGHT_PX = isMobile ? 340 : 275
export const SHORT_ITEM_HEIGHT_PX = 85
export const LINKS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT = 1
export const SHORTS_PAGE_FETCH_NEXT_LINKS_THRESHOLD_ROW_COUNT = 1
export const LINKS_PAGE_LINK_COLUMN_COUNT = isMobile ? 2 : 4

export const SHOPIFY_HELP_CENTER_URL = 'https://help.shopify.com/en'

export const TIME_UNITS = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year'
}

export const CONTACT_SUPPORT_EMAIL = 'support@paydin.net'
export const CONTACT_SUPPORT_ADDRESS = 'Yosef Lishanski 27, Rishon LeTsiyon'

export const ADMIN_PAGES = [
    LINKS_PAGE_URL,
    ACCOUNT_PAGE_URL
]

export const PROMOCODE_INPUT_MAX_LENGTH = 20
export const SALES_AND_DISCOUNT_STRIP_SENTENCE_1_INPUT_MAX_LENGTH = 50
export const SALES_AND_DISCOUNT_STRIP_SENTENCE_2_INPUT_MAX_LENGTH = 50

export const ACCOUNT_VERIFICATION_PAGE_DISPLAY_VERIFY_RESULT_DELAY_MILLISECONDS = 700
export const ACCOUNT_VERIFICATION_PAGE_PROCEED_DELAY_MILLISECONDS = 2500

export const EDIT_LINK_SECTION_LINK_DESCRIPTION_CHARACTER_LIMIT = 50

export const WELCOME_PAGE_FIRST_SECTION_BACKGROUND_COLOR = '#2dc2df'
export const WELCOME_PAGE_SECOND_SECTION_BACKGROUND_COLOR = '#8497b0'
export const WELCOME_PAGE_SECTION_IMAGE_BORDER_RADIUS_PX = 5
export const WELCOME_PAGE_THIRD_SECTION_BACKGROUND_COLOR = '#dfe2ed'

export const IMAGE_BOX_DEFAULT_BORDER_RADIUS_PX = 10

export const PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS_MENU_WIDTH_PX = 'fit-content'

export const PAYDIN_IMAGE_CROP_DIALOG_WIDTH = '90vmin'
export const PAYDIN_IMAGE_CROP_DIALOG_MAX_HEIGHT = '650px'
export const PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_WIDTH = '450px'
export const PAYDIN_IMAGE_CROP_DIALOG_INFLUENCER_MAX_HEIGHT = '500px'

export const PAYDIN_IMAGE_CROPPER_OBJECT_FITS = {
    CONTAIN: 'contain',
    VERTICAL_COVER: 'vertical-cover',
    HORIZONTAL_COVER: 'horizontal-cover'
}

export const PAYDIN_IMAGE_CROPPER_ASPECT_RATIOS = [
    {
        text: '1:1',
        ratio: 1 / 1
    },
    {
        text: '16:9',
        ratio: 16 / 9
    },
    {
        text: '4:5',
        ratio: 4 / 5
    }
]

export const PAYDIN_IMAGE_CROPPER_DEFAULT_ASPECT_RATIO = 1 / 1

export const PAYDIN_IMAGE_CROPPER_LOGO_ASPECT_RATIO = 4 / 1

export const LINKS_PAGE_LINKS_FILTER_COUNT = 28
export const SHORTS_PAGE_SHORTS_FILTER_COUNT = 28
export const MULTILINKS_PAGE_MULTILINKS_FILTER_COUNT = 28
export const INFLUENCER_PAGINATION_COUNT = 28

export const SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR = '#6B718E'
export const DEFAULT_CURRENCY_CODE = 'USD'

export const APPROVAL_MESSAGE_HIDDING_DELAY_MILLISECONDS = 2000

export const APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS = {
    OPTION1: 'shape1',
    OPTION2: 'shape2',
    OPTION3: 'shape3',
}

export const APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_OPTIONS = [
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION1,
        value: '0px',
        width: '60px',
        image: <div
            style={{
                width: '25px',
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '0px'
            }}>
        </div>
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION2,
        value: '10px',
        width: '60px',
        image: <div
            style={{
                width: '25px',
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '5px'
            }}>
        </div>
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CHECKOUT_BUTTON_SHAPE_KEYS.OPTION3,
        value: '25px',
        width: '60px',
        image: <div
            style={{
                width: '40px',
                height: '25px',
                backgroundColor: '#f6f6f6',
                border: '2px solid #b6b6b8',
                borderRadius: '25px'
            }}>
        </div>
    }
]

const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX = isMobile ? 90 : 100
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX = isMobile ? 140 : 150
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX = 1

const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SHARP_OPTION_BORDER_RADIUS_PX = 0
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SEMI_ROUNDED_OPTION_BORDER_RADIUS_PX = 6
const PRODUCT_IMAGE_STYLE_BORDER_RADIUS_ROUNDED_OPTION_BORDER_RADIUS_PX = 10

export const APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS = {
    OPTION1: 'shape1',
    OPTION2: 'shape2',
    OPTION3: 'shape3',
}
export const APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS = [
    {
        key: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION1,
        value: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SHARP_OPTION_BORDER_RADIUS_PX}px`,
        image: <img
            src={ProductImageStyleDisplayImage}
            style={{
                width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX}px`,
                height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX}px`,
                borderRadius: '0px',
                objectFit: 'cover'
            }}
        />,
        styles: {
            width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            cursor: 'pointer'
        },
        text: 'Sharp'
    },
    {
        key: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION2,
        value: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SEMI_ROUNDED_OPTION_BORDER_RADIUS_PX}px`,
        image: <img
            src={ProductImageStyleDisplayImage}
            style={{
                width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX}px`,
                height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX}px`,
                borderRadius: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_SEMI_ROUNDED_OPTION_BORDER_RADIUS_PX - PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX}px`,
                objectFit: 'cover'
            }}
        />,
        styles: {
            width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            cursor: 'pointer'
        },
        text: 'Semi-rounded'
    },
    {
        key: APPEARNACE_PAGE_PRODUCT_IMAGE_STYLE_BORDER_RADIUS_KEYS.OPTION3,
        value: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_ROUNDED_OPTION_BORDER_RADIUS_PX}px`,
        image: <img
            src={ProductImageStyleDisplayImage}
            style={{
                width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX}px`,
                height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX}px`,
                borderRadius: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_ROUNDED_OPTION_BORDER_RADIUS_PX - PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX}px`,
                objectFit: 'cover'
            }}
        />,
        styles: {
            width: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_WIDTH_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            height: `${PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTION_HEIGHT_PX + (PRODUCT_IMAGE_STYLE_BORDER_RADIUS_OPTIONS_BORDER_WIDTH_PX * 2)}px`,
            cursor: 'pointer'
        },
        text: 'Rounded'
    }
]

export const APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS = {
    OPTION1: 'cart1',
    OPTION2: 'cart2',
    OPTION3: 'cart3',
}

export const APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_OPTIONS = [
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION1,
        image: <ShoppingBagImage1
            style={{
                width: '30px',
                height: '30px'
            }} />
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION2,
        image: <ShoppingBagImage2
            style={{
                width: '30px',
                height: '30px'
            }} />
    },
    {
        key: APPEARANCE_PAGE_LINK_STYLE_CART_BUTTON_IMAGE_KEYS.OPTION3,
        image: <ShoppingBagImage3
            style={{
                width: '30px',
                height: '30px',
            }} />
    }
]

export const RESIZE_IMAGE_MAX_WIDTH = 1500
export const RESIZE_IMAGE_MAX_HEIGHT = 1500

export const INFLUENCER_LINK_PAGINATION_COUNT = 28
export const INFLUENCER_SHORT_PAGINATION_COUNT = 28

export const INPUT_VALIDATION_INDICATIONS = {
    NO_INDICATION: 'no-indication',
    VALID: 'valid',
    INVALID: 'invalid'
}

export const INFLUENCER_PROMOCODE_LENGTH_LIMIT = PROMOCODE_INPUT_MAX_LENGTH

export const EDIT_INFLUENCER_SECTION_VALIDATION_TIMEOUT_MILLISECONDS = 400

export const EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_HEIGHT_PX = 50
export const EDIT_LINK_SECTION_OPTIONS_MENU_ITEM_MAX_COUNT = 7

export const RUNNING_MESSAGES_STRIP_TEXT_SWAP_DELAY_MILLISECONDS = 5000
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_DURATION = 500
export const RUNNING_MESSAGES_STRIP_TEXT_NO_ANIMATION_NAME = 'none'
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_IN_NAME = 'in-message-anim'
export const RUNNING_MESSAGES_STRIP_TEXT_ANIMATION_OUT_NAME = 'out-message-anim'

export const PASSWORD_INPUT_RULESET = [
    'Must contain at least 6 characters',
    'Must contain at least 1 number',
    'Must contain at least 1 uppercase letter'
]
export const REDUCED_PADDING_PAGES = [
    LINKS_PAGE_URL,
    SHORTS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    ANALYTICS_PAGE_URL
]
export const NO_BOTTOM_PADDING_PAGES = [
    LINKS_PAGE_URL,
    SHORTS_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    SHOPBOARD_PAGE_URL,
    PRICING_PLANS_PAGE_URL
]

export const ADD_PRODUCTS_DIALOG_PRODUCT_STATUS = {
    ACTIVE: 'active',
    DRAFT: 'draft'
}
export const URL_PARAMETERS_LINK_TYPE_OPTIONS = {
    ORGANIC: 'Organic',
    INFLUENCERS: 'Influencers',
}
export const USERNAME_FIELD_PREFIX = 'paylinkshop.com/'

export const EDIT_SHORT_PROMOCODE_INPUT_MAX_LENGTH = 20

export const EDIT_SHORT_SECTION_URL_TEST_DELAY_MILLISECONDS = 400

export const LINK_TYPES = {
    LOOK: 'LOOK',
    SHORT: 'SHORT',
    SHOPBOARD: 'SHOPBOARD'
}

export const ANALYTICS_TAB_OPTIONS = [
    {
        text: 'Instants',
        image: <BiLink style={{ width: '15px', height: '15px' }} />
    },
    {
        text: 'Shorts',
        image: <ShortsImage style={{ width: '15px', height: '15px' }} />
    }
]

export const ANALYTICS_TAB_OPTIONS_WITH_SHOPBOARD = [
    ...ANALYTICS_TAB_OPTIONS,
    {
        text: isMobile ? 'Shop Feed' : 'Shop the Feed',
        image: <LiaShoppingBagSolid style={{ width: '15px', height: '15px' }} />
    }
]

export const INFLUENCER_TAB_OPTIONS = [
    {
        text: 'Insights',
        image: <InsightsImage style={{ width: '15px', height: '15px' }} />
    },
    {
        text: 'Instants',
        image: <BiLink style={{ width: '15px', height: '15px' }} />
    },
    {
        text: 'Shorts',
        image: <ShortsImage style={{ width: '15px', height: '15px' }} />
    }
]

export const SHOPBOARD_TAB_OPTIONS = [
    {
        text: 'Instagram',
        image: <></>
    },
    {
        text: 'TikTok',
        image: <></>
    },
    {
        text: 'Pinterest',
        image: <></>
    }
]

export const ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES = {
    LAST_24_HOURS: 'Last 24 hours',
    LAST_7_DAYS: 'Last 7 days',
    LAST_30_DAYS: 'Last 30 days',
    LAST_90_DAYS: 'Last 90 days',
    THIS_MONTH: 'This month',
    PREVIOUS_MONTH: 'Previous month',
    LIFETIME: 'Lifetime',
}

const TODAY_DATE = new Date()

export const ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS = [
    {
        id: 1,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_24_HOURS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_24_HOURS,
            TIME_UNITS.DAY,
            1
        )
    },
    {
        id: 2,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_7_DAYS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_7_DAYS,
            TIME_UNITS.DAY,
            7
        )
    },
    {
        id: 3,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_30_DAYS,
            TIME_UNITS.DAY,
            30
        )
    },
    {
        id: 4,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_90_DAYS,
        timeRange: calculateDateRange(
            ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LAST_90_DAYS,
            TIME_UNITS.DAY,
            90
        )
    },
    {
        id: 5,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH,
        timeRange: [
            new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth(), 1),
            TODAY_DATE
        ]
    },
    {
        id: 6,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.PREVIOUS_MONTH,
        timeRange: [
            new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth() - 1, 1),
            addTimeToDate(new Date(TODAY_DATE.getFullYear(), TODAY_DATE.getMonth(), 1), TIME_UNITS.DAY, -1)
        ]
    },
    {
        id: 7,
        name: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.LIFETIME,
        timeRange: [null, null]
    }
]

export const NOTIFY_STORE_CONNECT_FINISH_MESSAGE = 'CompletedPaylinkProcess'
export const NOTIFY_STORE_SUBSCRIPTION_FINISHED_MESSAGE = 'CompletedPaylinkSubscription'

export const ANALYTICS_STRIP_LIFETIME_REVENUE_DISPLAY_THRESHOLD = 1000
export const PAYDIN_CHART_TYPES = {
    LINE: 'line',
    BAR: 'bar',
    PIE: 'pie'
}

export const PAYDIN_CHART_CONTENT_PADDING_PX = 20
export const PAYDIN_CHART_CONTENT_GAP_PX = 35
export const PAYDIN_CHART_CONTENT_MOBILE_GAP_PX = 25

export const PREVIEW_LINK_SECTIONS_BACKGROUND_COLOR = '#F6F7FA'

export const UTM_FORM_INPUT_UTM_PARAM_ADDITIONS_OPTIONS_LIST = [
    {
        template: '{{link.influencer_username}}',
        description: 'The influencer associated to link (if exist)'
    },
    {
        template: '{{link.title}}',
        description: 'Link title'
    },
    {
        template: '{{link.id}}',
        description: 'Link ID'
    }
]
export const PAYDIN_CHART_GRAPH_HEIGHT_PX = 280

export const ADD_PRODUCTS_DIALOG_PRODUCTS_FRAME_DESKTOP_HEIGHT_PX = 440

export const PAYDIN_RADIO_BUTTON_GROUP_ALIGNMENTS = {
    ROW: 'row',
    COLUMN: 'column'
}

export const APPEARANCE_OPTION_PICKER_DEFAULT_BORDER_WIDTH_PX = 3
export const APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_BORDER_WIDTH_PX = 1
export const APPEARANCE_OPTION_PICKER_PRODUCT_IMAGE_STYLE_SELECTED_BORDER_WIDTH_PX = 2

export const DASHBOARD_PAGE_HORIZONTAL_PADDING_PX = 15

export const PAYDIN_DIALOG_ON_BUTTON_CLICKED_CALLBACK_DELAY_MILLISECONDS = 100

export const MOBILE_DASHBOARD_TOPBAR_RIGHT_SECTION_BUTTON_HEIGHT_PX = 50

export const DATE_PICKER_DESKTOP_WIDTH_PX = 660
export const DATE_PICKER_DESKTOP_BORDER_RADIUS_PX = 6
export const DATE_PICKER_DESKTOP_BORDER_COLOR = 'black'

export const ITEMS_VIEW_SECTION_MORE_ITEMS_LOADER_HEIGHT_PX = 60

export const ITEM_DESIGN_PATTERNS = {
    INSIDE: 'INSIDE',
    OUTSIDE: 'OUTSIDE'
}

export const SECTION_TYPES = {
    SINGLE: 'SINGLE',
    STRIP: 'STRIP',
    GRID: 'GRID'
}

export const PAYDIN_DRAWER_TRANSITION_DURATION = 250
export const PAYDIN_DRAWER_TRANSITION_DURATION_WITH_BUFFER = PAYDIN_DRAWER_TRANSITION_DURATION + 100

export const PAYDIN_DIALOG_TRANSITION_DURATION = 250

export const MULTI_SELECT_DIALOG_DESKTOP_HEIGHT_PX = 700
export const MULTI_SELECT_DIALOG_DONE_BUTTON_STRIP_HEIGHT_PX = 70
export const MULTI_SELECT_DIALOG_SELECTION_LIMIT_REACHED_ALERT_HIDE_DELAY_MILLISECONDS = 1500

export const EDIT_LINK_SECTION_ADD_COLLECTIONS_DIALOG_SELECTION_LIMIT = 5

export const ADD_PRODUCTS_DIALOG_RESET_FILTER_TRIGGER_DELAY_MILLISECONDS = 100
export const ADD_PRODUCTS_DIALOG_DESKTOP_COLUMN_COUNT = 7
export const ADD_PRODUCTS_DIALOG_MOBILE_COLUMN_COUNT = 3

export const ADD_COLLECTIONS_DIALOG_DESKTOP_COLUMN_COUNT = 6
export const ADD_COLLECTIONS_DIALOG_MOBILE_COLUMN_COUNT = 3

export const SHORT_URL_SELECTION_TYPE = {
    CUSTOM: 'CUSTOM',
    PRODUCT: 'PRODUCT',
    COLLECTION: 'COLLECTION',
    ORGANIC: 'ORGANIC'
}

export const MULTILINK_SUBLINK_SELECTION_TYPE = {
    CUSTOM: 'CUSTOM',
    PRODUCT: 'PRODUCT',
    COLLECTION: 'COLLECTION',
    LOOK: 'LOOK',
}


export const MULTILINK_SUBLINK_TYPES = {
    SHORT: 'SHORT',
    LOOK: 'LOOK',
}

export const MULTILINK_SUBLINK_DEVICE_OPTIONS_VALUE_ELEMENT = [
    {
        value: 'ALL',
        element: 'All devices'
    }, 
    {
        value: 'MOBILE', 
        element: 'Mobile only'
    }, 
    {
        value: 'PC', 
        element: 'Desktop only'
    }
]
export const MULTILINK_SUBLINK_DEVICE_OPTIONS = {
    ALL: 'All devices',
    MOBILE: 'Mobile only',
    PC: 'Desktop only'
}

export const SHORT_URL_SELECTION_TYPE_ELEMENT_IMAGE_BORDER_RADIUS = 20
export const SHORT_URL_SELECTION_TYPE_SELECTED_ELEMENT_BORDER_WIDTH = 3

export const SHORT_INFO_URL_NO_CUSTOM_IMAGE_DIMENS_PX = 118
export const SHORT_INFO_URL_CUSTOM_IMAGE_DIMENS_PX = 70

export const INFLUENCER_INSIGHTS_AOV_HEIGHT = '60%'
export const INFLUENCER_INSIGHTS_CR_HEIGHT = '40%'
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_PIE_WIDTH = 250
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_WIDTH = isMobile ? '100%' : 380
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_HEIGHT = 360
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_WIDTH = isMobile ? '100%' : 290
export const INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_HEIGHT = 115
export const INFLUENCER_INSIGHTS_ROW_COLUMN_GAP = 15
export const INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_WIDTH = isMobile ? '50%' : (INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CHART_WIDTH - INFLUENCER_INSIGHTS_ROW_COLUMN_GAP) / 2
export const INFLUENCER_INSIGHTS_SINGLE_VALUE_CONTAINER_HEIGHT = INFLUENCER_INSIGHTS_SALES_BREAKDOWN_CONTAINER_HEIGHT
export const INFLUENCER_INSIGHTS_LINKS_COLOR = '#28B2AF'
export const INFLUENCER_INSIGHTS_SHORTS_COLOR = '#3596FF'
export const INFLUENCER_INSIGHTS_ORGANIC_COLOR = '#B900D8'

export const HOME_PAGE_RECENT_ACTIVITIES_TABS = {
    INSTANTS: 'INSTANTS',
    SHORTS: 'SHORTS',
    INFLUENCERS: 'INFLUENCERS'
}
export const HOME_PAGE_TASKS = {
    ADD_LOGO: 'ADD_LOGO',
    CUSTOMIZE_THEME: 'CUSTOMIZE_THEME',
    ADD_ANALYTICS: 'ADD_ANALYTICS',
    ADD_SHIPPING_AND_RETURN: 'ADD_SHIPPING_AND_RETURN',
    ADD_UTM: 'ADD_UTM'
}

export const PAYDIN_ACCORDION_HEADER_GAP_PX = 5

export const HOME_PAGE_GET_LINKS_API_COUNT = 4
export const HOME_PAGE_DESKTOP_GET_SHORTS_API_COUNT = 6
export const HOME_PAGE_MOBILE_GET_SHORTS_API_COUNT = 3
export const HOME_PAGE_DESKTOP_GET_INFLUENCERS_API_COUNT = 4
export const HOME_PAGE_MOBILE_GET_INFLUENCERS_API_COUNT = 4

export const APPEARANCE_PAGE_LOGO_SECTION_ID = 'appearance_page__logo_section'
export const APPEARANCE_PAGE_THEME_SECTION_ID = 'appearance_page__theme_section'

export const SETTINGS_PAGE_UTM_SECTION_ID = 'settings-page-utm-section'
export const SETTINGS_PAGE_SHIPPING_AND_RETURN_SECTION_ID = 'settings-page-shipping-and-return-section'
export const SETTINGS_PAGE_ANALYTICS_SECTION_ID = 'settings-page-analytics-section'

export const SCROLL_TO_ID_DELAY_MILLISECONDS = 100

export const DASHBOARD_TOPBAR_HEIGHT_PX = 70
export const MOBILE_PAGE_VIEW_TOPBAR_HEIGHT_PX = 45

export const PRODUCT_TABLE_HEADERS_IDS = {
    IMAGE: "productImage",
    TITLE: "productName",
    VIEW: "viewCount",
    ADD: "addCount",
    BUY: "buyCount",
    RANK: "ranking",
}
export const ORDERS_TABLE_HEADERS_IDS = {
    DATE: 'createdAt',
    SOURCE: 'source',
    INFLUENCER: 'influencer',
    TOTAL_PRODUCTS_COUNT: 'totalProductsCount',
    TOTAL_PRICE: 'totalPrice',
    STATUS: 'status',
    ACTIONS: 'actions',
    LINK_SOURCE_TITLE: 'linkTitle'
}
export const ORDERS_TABLE_STATUS_OPTIONS = {
    PAID: 'PAID',
    REFUNDED: 'REFUNDED',
    PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED'
}

export const MULTILINK_EXPERIENCES_TABLE_HEADERS_IDS = {
    NAME: 'name',
    TYPE: 'type',
    DEVICE: 'device',
    TRAFFIC: 'traffic',
    CLICKS: 'clicks',
    SALES: 'sales',
    REVENUE: 'revenue',
    CR: 'cr',
    CART_AVERAGE: 'aov',
}


export const PRODUCTS_TABLE_ROW_HEIGHT_PX = 105
export const MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX = 95
export const ORDERS_TABLE_ROW_HEIGHT_PX = 62
export const MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX = 62
export const STORE_ORDERS_TABLE_ROW_HEIGHT_PX = 100
export const ORDERS_TABLE_PRODUCT_ROW_HEIGHT_PX = isMobile ? 70 : 80
export const ORDERS_TABLE_PRODUCT_LIST_GAP = isMobile ? 10 : 15
export const MOBILE_ORDERS_TABLE_ROW_HEIGHT_PX = 76
export const MOBILE_STORE_ORDERS_TABLE_ROW_HEIGHT_PX = 90
export const MOBILE_MULTILINK_EXPERIENCES_TABLE_ROW_HEIGHT_PX = 62
export const PRODUCTS_TABLE_ROW_HEIGHT = isMobile ? MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX : PRODUCTS_TABLE_ROW_HEIGHT_PX
export const ORDERS_TABLE_ROW_HEIGHT = isMobile ? MOBILE_ORDERS_TABLE_ROW_HEIGHT_PX : ORDERS_TABLE_ROW_HEIGHT_PX

export const PRODUCTS_TABLE_ROWS_PER_PAGE = 4
export const ORDERS_TABLE_ROWS_PER_PAGE = 5
export const STORE_ORDERS_TABLE_ROWS_PER_PAGE = ORDERS_TABLE_ROWS_PER_PAGE
export const STORE_ORDERS_TABLE_COUNT = 20

export const DATE_PICKER_DATE_INTERVAL_OPTION_WIDTH_PX = 115

export const OPTION_VALUE_MAPPER_VALUE_STATUS = {
    VERIFIED: 'VERIFIED',
    NOT_VERIFIED: 'NOT_VERIFIED'
}
export const OPTION_VALUE_MAPPER_OPTION_TYPES = {
    COLOR: 'COLOR',
    TEXT: 'TEXT'
}

export const OPTION_VALUE_MAPPER_PAGE_COUNT = 8
export const OPTION_VALUE_MAPPER_ROW_HEIGHT = 30
export const OPTION_VALUE_MAPPER_ROW_GAP = 10

export const DASHBOARD_SIDEBAR_WIDTH_PX = 240
export const DASHBOARD_SIDEBAR_MINIMIZED_WIDTH_PX = 65
export const DASHBOARD_SIDEBAR_USER_MENU_WIDTH = DASHBOARD_SIDEBAR_WIDTH_PX + 110
export const DASHBOARD_SIDEBAR_USER_MENU_BORDER_RADIUS_PX = 8

export const PAYDI_MENU_TRANSITION_DURATION_MILLISECONDS = 300

export const CONTENT_TYPES = {
    LINK: 'LINK',
    SHORT: 'SHORT',
    INFLUENCER: 'INFLUENCER',
    MULTILINK: 'MULTILINK',
}

export const ALERT_MESSAGE_TYPES = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
}

export const SHOPBOARD_SOCIALS = {
    INSTAGRAM: 'instagram',
    TIKTOK: 'tiktok',
    PINTEREST: 'pinterest'
}

export const SHOPBOARD_POST_LIST_COUNT = 15
export const NUMBER_TYPES = {
    PERCENTAGE: 'PERCENTAGE',
    SHORT: 'SHORT',
    NORMAL: 'NORMAL',
    PRICE: 'PRICE'
}

export const LINK_PRODUCT_SELECT_MAX_LIMIT = 15

export const FREE_TRIAL_STRIP_HEIGHT = 50

export const INFLUENCER_PREVIEW_LINKS_PAGINATION_OFFSET = 4
export const INFLUENCER_PREVIEW_SHORTS_PAGINATION_OFFSET = 2

export const NOT_PRO_MAX_INFLUENCER_NUMBER = 3

export const TRENDS_PAGE_PRODUCT_IMAGE_HEIGHT_PX = 110
export const TRENDS_PAGE_PRODUCT_IMAGE_WIDTH_PX = 100
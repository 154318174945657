import React, { useEffect, useState } from 'react'
import './PreviewMultilinkSection.css'
import LinkAnalyticsSection from 'components/LinkAnalyticsSection/LinkAnalyticsSection'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/utils'
import { getCurrencySymbol } from 'services/currency'
import { isDesktop, isMobile } from 'react-device-detect'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { copyToClipboard } from 'services/SystemService'
import { useHistoryContext } from 'contexts/History'
import { environment } from 'conf'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { DeleteMultilinkAPI, GetMultilinkDetailsAPI } from 'api/links/links'
import { CONTENT_TYPES, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY, MULTILINK_SUBLINK_SELECTION_TYPE, MULTILINK_SUBLINK_TYPES, NUMBER_TYPES, PAYDIN_DRAWER_TRANSITION_DURATION, PAYDIN_MENU_OPENING_DIRECTIONS, VIEW_MODES } from 'constants/general.constants'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import ContentDialogLoader from 'components/ContentDialogLoader/ContentDialogLoader'
import { extractCollectionData, extractProductData } from 'utils/dataUtils'
import PreviewItemDataSection from 'components/PreviewItemDataSection/PreviewItemDataSection'
import { MULTILINKS_PAGE_URL } from 'constants/routes.constants'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import { MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md'
import DrawerStyledButtonList from 'components/DrawerStyledButtonList/DrawerStyledButtonList'
import MultilinkExperiencesTable from 'components/MultilinkExperiencesTable/MultilinkExperiencesTable'

/**
 * Represents the section in which the multilink data is displayed.
 */
export default function PreviewMultilinkSection({
    type,
    data = {},
    onDeleteMultilink = () => { },
    onSaveSuccess = () => { }
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const { 
        userDetails,
        calculateRevenue,
        isFreeUserAndTrialEnded
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        openDrawer,
        closeDrawer,
        openFreeTrialDialog,
        finishedUpdatingObject
    } = useGeneralDataContext()

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [contentDialogState, setContentDialogState] = useState({
        isOpen: false,
        contentType: CONTENT_TYPES.MULTILINKLINK
    })
    const [isLoadingMultilink, setIsLoadingMultilink] = useState(true)
    const [multilinkData, setMultilinkData] = useState({})
    const [urlSelectionTypes, setUrlSelectionTypes] = useState(['',''])
    const [multilinkAnalyticsData, setMultilinkAnalyticsData] = useState({
        isLoading: true,
        views: 0,
        checkouts: 0,
        revenue: 0
    })
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })

    useEffect(() => {
        loadMultilink()
    }, [])


    useEffect(() => {
        if (finishedUpdatingObject) {
            loadMultilink()
        }
    }, [finishedUpdatingObject, ])

    function loadMultilink() {
        GetMultilinkDetailsAPI(data?.multilinkId)
            .then(multilinkData => {
                const utmParams = {
                    UTMSource: multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_source ?? '') : '',
                    UTMMedium: multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_medium ?? '') : '',
                    UTMCampaign: multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_campaign ?? '') : '',
                    UTMTerm: multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_term ?? '') : '',
                    UTMContent: multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.utm_params?.utm_content ?? '') : '',
                }
                const promocode = multilinkData?.links_options && multilinkData?.links_options?.length > 0 ? (multilinkData?.links_options[0]?.link_data?.promocode?.code ?? (multilinkData?.links_options[0]?.link_data?.promocode ?? '')) : ''
        
                const linksOptions = (multilinkData?.links_options ?? []).map((linkOption, index) => {
                    return {
                        linkData: {
                            shoppingRedirectUrl: linkOption?.link_data?.shopping_redirect_url ?? '',
                            description: linkOption.link_data?.description ?? '',
                            views: linkOption?.link_data?.link_analytics?.visits ?? 0,
                            checkouts: linkOption?.link_data?.link_analytics?.checkouts ?? 0,
                            revenue: linkOption?.link_data?.link_analytics?.revenue ?? 0,
                            totalTax: linkOption?.link_data?.link_analytics?.total_tax ?? 0,
                            linkType: linkOption?.link_data?.link_type ??  '',
                            urlType: linkOption?.link_data?.url_type ??  '',
                            linkDefaultImage: linkOption?.link_data?.link_default_image ??  '',
                            products: (linkOption?.link_data?.products ?? []).map(product => extractProductData(product)),
                            collections: (linkOption?.link_data?.collections ?? []).map(collection => extractCollectionData(collection))
                        },
                        value: linkOption?.value ?? 0,
                        linkId: linkOption?.linkData?.linkId ?? (linkOption?.link_data?.link_id ?? ''),
                        deviceOption: linkOption.deviceOption ?? (linkOption?.device_option ?? ''),
                    }
                })
        
                setMultilinkData({
                    multilinkId: multilinkData?.multilinkId ?? (multilinkData?.multilink_id ?? ''),
                    title: multilinkData.title ?? '',
                    currency: multilinkData?.currency ?? (multilinkData?.link_analytics?.currency ?? userDetails?.businessCurrency),
                    promocode: promocode,
                    title: multilinkData?.title ?? "",
                    linksOptions,
                    utmParams
                })
        
                setIsLoadingMultilink(false)
                setUrlSelectionTypes(getUrlSelectionTypes(linksOptions))
                setMultilinkAnalyticsData({
                    isLoading: false,
                    views: linksOptions.reduce((acc, linkOption) => acc + (parseInt(linkOption?.linkData?.views) ?? 0), 0),
                    checkouts: linksOptions.reduce((acc, linkOption) => acc + (parseInt(linkOption?.linkData?.checkouts) ?? 0), 0),
                    revenue: linksOptions.reduce((acc, linkOption) => acc + (parseFloat(linkOption?.linkData?.revenue) ?? 0), 0),
                    totalTax: linksOptions.reduce((acc, linkOption) => acc + (parseFloat(linkOption?.linkData?.totalTax) ?? 0), 0)
                })
            })
            .catch(error => {
                console.log(error)
                history.push(MULTILINKS_PAGE_URL)
            })
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function getUrlSelectionTypes(linksOptions) {
        return linksOptions?.map(linkOption => {
            if (linkOption?.linkData?.linkType == MULTILINK_SUBLINK_TYPES.SHORT) {
                if (linkOption?.linkData?.products && linkOption?.linkData?.products?.length > 0) {
                    return MULTILINK_SUBLINK_SELECTION_TYPE.PRODUCT
                }
        
                if (linkOption?.linkData?.collections && linkOption?.linkData?.collections?.length > 0) {
                    return MULTILINK_SUBLINK_SELECTION_TYPE.COLLECTION
                }
        
                return MULTILINK_SUBLINK_SELECTION_TYPE.CUSTOM
            }
            return MULTILINK_SUBLINK_SELECTION_TYPE.LOOK
        })
    }

    function closeContentDialog() {
        setContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function edit() {
        if (isMobile) {
            closeDrawer()
        } else {
            optionsMenuState.onClose()
        }

        setTimeout(() => {
            if (isFreeUserAndTrialEnded()) {
                openFreeTrialDialog()
            } else {
                setContentDialogState({
                    isOpen: true,
                    contentType: CONTENT_TYPES.MULTILINK
                })
            }
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function copyMultilink() {
        if (isMobile) {
            closeDrawer()
        } else {
            optionsMenuState.onClose()
        }

        setTimeout(async () => {
            await copyToClipboard(`${environment.frontend_url}${userDetails?.username}/${multilinkData?.multilinkId}`)
            showApprovalMessage(t('MULTILINK_COPIED_TO_CLIPBOARD'))
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function onDeleteClick() {
        if (isMobile) {
            history.goBack()
            setTimeout(() => {
                showDeleteConfirmationDialog()
            }, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY)
        } else {
            optionsMenuState.onClose()
            showDeleteConfirmationDialog()
        }
    }

    function showDeleteConfirmationDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('PREVIEW_MULTILINK_PAGE_DELETE_MULTILINK_DIALOG_TITLE'),
            message: t('PREVIEW_MULTILINK_PAGE_DELETE_MULTILINK_DIALOG_MESSAGE'),
            leftButtonText: t('PREVIEW_MULTILINK_PAGE_DELETE_MULTILINK_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('PREVIEW_MULTILINK_PAGE_DELETE_MULTILINK_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: deleteMultilink
        }))
    }

    function deleteMultilink() {
        DeleteMultilinkAPI(data?.multilinkId)
            .then(_ => {
                onDeleteMultilink(data?.multilinkId)
                setTimeout(() => {
                    history.goBack()
                }, 200)
            })
            .catch(error => {
                console.log(error)
                setDialogState(prev => ({
                    ...prev,
                    title: t('GENERAL_ERROR_DIALOG_TITLE'),
                    message: t('GENERAL_ERROR_DIALOG_MESSAGE'),
                    leftButtonText: t('GENERAL_ERROR_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: ''
                }))
            })
    }

    function openOptionsDrawer() {
        openDrawer(true, <>
            <div className="mobile-multilink-item-more-options-menu-container">
                <div className="mobile-multilink-item-more-options-menu-item-line">
                    <div className="mobile-multilink-item-more-options-menu-single-item" onClick={copyMultilink}>
                        <MdOutlineContentCopy className="mobile-multilink-item-more-options-menu-single-item-image" />
                        <div className="mobile-multilink-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK')}</div>
                    </div>
                </div>
                <DrawerStyledButtonList
                    buttons={[
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            image: <MdOutlineEdit className="mobile-multilink-item-more-options-menu-section-item-image" />,
                            onClick: edit
                        },
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            image: <RiDeleteBin6Line className="mobile-multilink-item-more-options-menu-section-item-image" />,
                            className: 'mobile-multilink-item-more-options-menu-section-item-delete-multilink',
                            onClick: onDeleteClick
                        }
                    ]}
                />
            </div>
        </>)
    }

    function renderMultilinkAnalytics() {
        return <div className="preview-multilink-section-multilink-analytics">
            <div className={isMobile ? "mobile-preview-multilink-section-multilink-analytics-data" : "preview-multilink-section-multilink-analytics-data"}>
                <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_VIEWS_TITLE')}
                    value={formatNumber(Math.max(multilinkAnalyticsData?.views ?? 0, multilinkAnalyticsData?.checkouts), NUMBER_TYPES.SHORT)}
                />
                <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CHECKOUTS_COMPLETED_TITLE')}
                    value={formatNumber(multilinkAnalyticsData?.checkouts, NUMBER_TYPES.SHORT)}
                />
                <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_REVENUE_TITLE')}
                    value={`${getCurrencySymbol(multilinkData?.currency)}${formatNumber(calculateRevenue(multilinkAnalyticsData?.revenue, multilinkAnalyticsData?.totalTax), NUMBER_TYPES.SHORT)}`}
                />
                <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CR_TITLE')}
                    value={`${formatNumber((multilinkAnalyticsData?.checkouts / multilinkAnalyticsData?.views) * 100, NUMBER_TYPES.PERCENTAGE)}%`}
                />
                <LinkAnalyticsSection
                    title={t('PREVIEW_LINK_SECTION_ANALYTICS_SECTION_CART_AVERAGE_TITLE')}
                    value={`${getCurrencySymbol(multilinkData?.currency)}${formatNumber(multilinkAnalyticsData?.revenue / multilinkAnalyticsData?.checkouts, NUMBER_TYPES.SHORT, true)}`}
                />
            </div>
        </div>
    }

    return (
        <div className={isMobile ? 'mobile-preview-multilink-container' : 'preview-multilink-container'}>
            <div className={isMobile ? 'mobile-preview-multilink-section-container' : 'preview-multilink-section-container'}>
                <div className={isMobile ? 'mobile-preview-multilink-section-details' : 'preview-multilink-section-details'} style={{ width: '100%' }}>
                    <ContentDialogTopbar
                        title={t('MULTILINK_VIEW_TOPBAR_CENTER_TITLE_PREVIEW_MULTILINK')}
                        onBackButtonClick={() => history.goBack()}
                        rightElement={
                            <BiDotsHorizontalRounded className='multilink-view-more-options-button' onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                        }
                    />
                    {isLoadingMultilink && <ContentDialogLoader />}
                    <div className={isMobile ? "mobile-preview-multilink-section-multilink-details-container" : "preview-multilink-section-multilink-details-container"}>
                        <div className={isMobile ? "mobile-preview-multilink-section-multilink-details" : "preview-multilink-section-multilink-details"} style={{ height: 'fit-content' }}>
                            <div className='preview-multilink-section-multilink-description-container' style={{ width: '100%' }}>
                                {
                                    multilinkData?.title && <MultiLineText
                                        text={multilinkData?.title}
                                        className='preview-multilink-section-multilink-description'
                                        lines={1}
                                        oneWord={true}
                                    />
                                }
                                {
                                    !isMobile && renderMultilinkAnalytics()
                                }
                            </div>
                        </div>
                        {isMobile && renderMultilinkAnalytics()}
                        {
                            !isLoadingMultilink && multilinkData?.promocode && <div className={isMobile ? "mobile-preview-multilink-section-promocode" : "preview-multilink-section-promocode"}>
                                <div className='preview-multilink-section-promocode-label'>{t('PREVIEW_LINK_SECTION_PROMOCODE_SECTION_TITLE')}</div>
                                <div className='preview-multilink-section-promocode-value'>{multilinkData?.promocode}</div>
                            </div>
                        }
                    </div>
                    <div className={isMobile ? "mobile-preview-multilink-section-multilink-graphs" : "preview-multilink-section-multilink-graphs"} style={{ maxWidth: isMobile ? '100vw' : '65vw', paddingInline: isMobile ? '0' : '2.5vw' }}>
                        {
                            !isLoadingMultilink && <PreviewItemDataSection
                                title={t('MULTILINKS_PREVIEW_SUBLINKS_TABLE_TITLE')}
                                NoBackgroundColor={true}
                                hasPadding={true}
                                width='100%'
                            >
                                <MultilinkExperiencesTable
                                    linksOptions={multilinkData?.linksOptions ?? []}
                                    currency={multilinkData?.currency}
                                />
                            </PreviewItemDataSection>
                        }
                    </div>
                </div>
            </div>
            {
                isDesktop && <PaydinMenu
                    anchorElement={optionsMenuState.anchorElement}
                    isOpen={optionsMenuState.isOpen}
                    onClose={optionsMenuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                    options={[
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            onClick: edit
                        },
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK'),
                            onClick: copyMultilink
                        },
                        {
                            className: 'multilink-view-options-menu-delete-option',
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            onClick: onDeleteClick
                        }
                    ]}
                />
            }
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                title={dialogState.title}
                message={dialogState.message}
                handleDialogClose={closeDialog}
                closeOnRightClick={false}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                isLeftButtonWithLoader={false}
                rightButtonText={dialogState.rightButtonText}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
            />
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeContentDialog}
                data={{
                    multilinkId: multilinkData?.multilinkId,
                    multilinkData
                }}
                contentType={contentDialogState.contentType}
                mode={VIEW_MODES.EDIT}
                onSaveSuccess={updatedMultilink => {
                    loadMultilink()
                    onSaveSuccess(updatedMultilink)
                }}
            />
        </div>
    )
}
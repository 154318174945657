import { request_auth } from 'services/RequestWrapper';
import { environment } from "conf";
import { PRODUCT_COMPLEMENTARY_API_URL, PRODUCT_SEARCH_API_URL, PRODUCT_TOP_COMBINATIONS_API_URL, PRODUCT_TOP_PERFORMING_API_URL } from 'constants/api.constants';
import { IS_OUT_OF_STOCK_QUERY_PARAMETER, PRODUCT_STATUS_QUERY_PARAMETER, IS_SEARCH_IN_TAGS_QUERY_PARAMETER } from 'constants/routes.constants';

/*
    Retrieves all products.
    Method: [GET]
    URL: /admin/v1/products/search

    Query params:
    =============
        (*) q - The search query string
        (*) count - The page capacity
        (*) skip - The number of elements skiped
*/
export function ProductSearchAPI(q, count, skip, filters) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.base_url + PRODUCT_SEARCH_API_URL + `?q=${q}&count=${count}&skip=${skip}${getFiltersQuery(filters)}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    });
}

function getFiltersQuery(filters) {
    let filterQuery = ''
    if (filters?.active !== filters?.draft) {
        if (filters?.active) {
            filterQuery += `&${PRODUCT_STATUS_QUERY_PARAMETER}=active`
        } else {
            filterQuery += `&${PRODUCT_STATUS_QUERY_PARAMETER}=draft`
        }
    }

    if (filters?.outOfStock) {
        filterQuery += `&${IS_OUT_OF_STOCK_QUERY_PARAMETER}=${filters?.outOfStock}`
    }

    if (filters?.searchInTags) {
        filterQuery += `&${IS_SEARCH_IN_TAGS_QUERY_PARAMETER}=${filters?.searchInTags}`
    }

    return filterQuery
}


/*
    Retrieves best sellers.
    Method: [GET]
    URL: /admin/v1/products/trends/top-performing
*/
export function GetTopPerformingProductsAPI() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.base_url + PRODUCT_TOP_PERFORMING_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    });
}

/*
    Retrieves best combinations.
    Method: [GET]
    URL: /admin/v1/products/trends/top-combinations
*/
export function GetTopProductsCombinationsAPI() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.base_url + PRODUCT_TOP_COMBINATIONS_API_URL, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    });
}


/*
    Retrieves complementary producgts.
    Method: [GET]
    URL: /admin/v1/products/trends/complementary/<inventory_id>
*/
export function GetComplementaryProductsAPI(inventoryId) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request_auth(environment.base_url + PRODUCT_COMPLEMENTARY_API_URL + `/${inventoryId}`, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    });
}
import React, { useEffect, useState } from 'react'
import './InfluencerView.css'
import { CONTENT_TYPES, CREATE_LINK_REDIRECT_TO_PAGE_DELAY, INPUT_VALIDATION_INDICATIONS, MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY, PAYDIN_MENU_OPENING_DIRECTIONS, SEPERATE_GO_BACK_FOR_SAFARY_DELAY, VIEW_MODES } from 'constants/general.constants'
import { t } from 'i18next'
import EditInfluencerSection from 'components/EditInfluencerSection/EditInfluencerSection'
import { useHistoryContext } from 'contexts/History'
import { GetInfluencer, createInfluencer, deactivateInfluencer, resetInfluencerPassword, updateInfluencer } from 'api/influencers/influencers'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { BrowserView, isMobile } from 'react-device-detect'
import { uploadFile } from 'utils/utils'
import { logger } from 'services/CloudLogger'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import {
    extractInfluencerData
} from 'utils/dataUtils'
import { copyToClipboard } from 'services/SystemService'
import { INFLUENCERS_PAGE_URL, LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER, LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER, LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER, LOGIN_INFLUENCER_PAGE_URL } from 'constants/routes.constants'
import ContentDialogLoader from 'components/ContentDialogLoader/ContentDialogLoader'

/**
 * Represents a portion of the screen that displays the influencer's edit/create panel.
 * @param {string} mode - The mode of this view, it determins whether it is creating or editing an influencer, accepts VIEW_MODES.CREATE or VIEW_MODES.EDIT
 */
export default function InfluencerView({
    mode,
    data
}) {
    const history = useHistoryContext()
    const {
        userDetails,
        setRecentInfluencers,
        beginEditing,
        endEditing
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        setObjectToSave,
        setFinishedCreatingObject,
        setFinishedUpdatingObject
    } = useGeneralDataContext()

    const [influencerData, setInfluencerData] = useState({
        username: '',
        image: '',
        email: '',
        utm: '',
        promocode: '',
        commission: null,
        password: '',
        analytics: {
            clicks: 0,
            sales: 0,
            revenue: 0
        },
        links: [],
        shorts: []
    })
    const [isLoadingInfluencer, setIsLoadingInfluencer] = useState(false)
    const [isButtonProcessing, setIsButtonProcessing] = useState(false)
    const [isAbleToSaveInfluencer, setIsAbleToSaveInfluencer] = useState(false)
    const [influencerPropertiesModified, setInfluencerPropertiesModified] = useState(false)
    const [image, setImage] = useState('')
    const [username, setUsername] = useState('')
    const [isUsernameValid, setIsUsernameValid] = useState(false)
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [email, setEmail] = useState('')
    const [originalEmail, setOriginalEmail] = useState(null)
    const [promocode, setPromocode] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberValidationStatus, setPhoneNumberValidationStatus] = useState(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
    const [phoneNumberHelperText, setPhoneNumberHelperText] = useState('')
    const [commission, setCommission] = useState(0)
    const [commissionValidationStatus, setCommissionValidationStatus] = useState(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
    const [commissionHelperText, setCommissionHelperText] = useState('')
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { },
        leftButtonClickHandler: () => { },
    })
    const [influencerPermissions, setInfluencerPermissions] = useState({
        clicksEnabled: false,
        salesEnabled: false,
        revenueEnabled: false,
        includeVat: false,
        isActiveInfluencer: false
    })
    const [shouldTestEmailValidationStatus, setShouldTestEmailValidationStatus] = useState(false)

    useEffect(() => {
        if (mode === VIEW_MODES.EDIT) {
            setIsLoadingInfluencer(true)
            GetInfluencer(data?.influencerUsername)
                .then(response => {
                    setImage(response?.profile_image_url ?? '')
                    setUsername(response?.username ?? '')
                    setEmail(response?.email ?? '')
                    setOriginalEmail(response?.email ?? '')
                    setPromocode(response?.promocode ?? '')
                    setPhoneNumber(response?.phone ?? '')
                    setCommission(!response?.commission ? 0 : response?.commission)
                    setInfluencerPermissions({
                        clicksEnabled: response?.permissions?.analytics_clicks_enabled ?? false,
                        salesEnabled: response?.permissions?.analytics_sales_enabled ?? false,
                        revenueEnabled: response?.permissions?.analytics_revenue_enabled ?? false,
                        includeVat: response?.permissions?.analytics_include_vat ?? false,
                        isActiveInfluencer: response?.permissions?.allow_creating_and_editing_links ?? false
                    })
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoadingInfluencer(false)
                    setShouldTestEmailValidationStatus(true)
                })
        }
    }, [])

    useEffect(() => {
        if (influencerPropertiesModified) {
            beginEditing()
            addDiscardChangesDialogBackHandler()
        } else {
            endEditing()
        }
    }, [influencerPropertiesModified])

    useEffect(() => {
        setIsAbleToSaveInfluencer(
            influencerPropertiesModified &&
            (mode === VIEW_MODES.CREATE ? (isUsernameValid && isEmailValid) : (username && username.length > 0 && email && email.length > 0)) &&
            image &&
            commissionValidationStatus !== INPUT_VALIDATION_INDICATIONS.INVALID &&
            phoneNumberValidationStatus !== INPUT_VALIDATION_INDICATIONS.INVALID
        )
    }, [influencerPropertiesModified, image, username, email, isUsernameValid, isEmailValid, commissionValidationStatus, phoneNumberValidationStatus])

    function setInfluencerClicksPermission(allowPermission) {
        setInfluencerPropertiesModified(true)
        setInfluencerPermissions(prev => ({
            ...prev,
            clicksEnabled: allowPermission
        }))
    }

    function setInfluencerSalesPermission(allowPermission) {
        setInfluencerPropertiesModified(true)
        setInfluencerPermissions(prev => ({
            ...prev,
            salesEnabled: allowPermission
        }))
    }

    function setInfluencerRevenuePermission(allowPermission) {
        setInfluencerPropertiesModified(true)
        setInfluencerPermissions(prev => ({
            ...prev,
            revenueEnabled: allowPermission,
            ...(!allowPermission ? { includeVat: false } : {})
        }))
    }

    function setInfluencerIncludeVATPermission(allowPermission) {
        setInfluencerPropertiesModified(true)
        setInfluencerPermissions(prev => ({
            ...prev,
            includeVat: allowPermission
        }))
    }

    function getTitle() {
        switch (mode) {
            case VIEW_MODES.PREVIEW: return data?.influencerUsername
            case VIEW_MODES.CREATE: return t('INFLUENCER_VIEW_CREATE_TITLE')
            default: return t('INFLUENCER_VIEW_EDIT_TITLE')
        }
    }

    function setInfluencerProfileImage(image) {
        setInfluencerPropertiesModified(true)
        setImage(typeof image === 'string' && image.startsWith('blob') ? image : URL.createObjectURL(image),)
    }

    function setInfluencerUsername(username) {
        setInfluencerPropertiesModified(true)
        setUsername(username)
    }

    function setInfluencerEmail(email) {
        setInfluencerPropertiesModified(true)
        setEmail(email)
    }

    function setInfluencerPromocode(promocode) {
        setInfluencerPropertiesModified(true)
        setPromocode(promocode)
    }

    function setInfluencerCommission(commission) {
        setInfluencerPropertiesModified(true)
        setCommission(commission)
    }

    function setInfluencerPhoneNumber(phone) {
        setInfluencerPropertiesModified(true)
        setPhoneNumber(phone)
    }

    function setInfluencerActivity(isActive) {
        setInfluencerPropertiesModified(true)
        setInfluencerPermissions(prev => ({
            ...prev,
            isActiveInfluencer: isActive
        }))
    }

    function renderContent() {
        if (mode === VIEW_MODES.EDIT) {
            return <EditInfluencerSection
                mode={mode}
                image={image}
                setImage={setInfluencerProfileImage}
                username={username}
                email={email}
                originalEmail={originalEmail}
                setEmail={setInfluencerEmail}
                setIsEmailValid={setIsEmailValid}
                promocode={promocode}
                setPromocode={setInfluencerPromocode}
                commission={commission}
                setCommission={setInfluencerCommission}
                commissionValidationStatus={commissionValidationStatus}
                setCommissionValidationStatus={setCommissionValidationStatus}
                commissionHelperText={commissionHelperText}
                setCommissionHelperText={setCommissionHelperText}
                phoneNumber={phoneNumber}
                setPhoneNumber={setInfluencerPhoneNumber}
                phoneNumberValidationStatus={phoneNumberValidationStatus}
                setPhoneNumberValidationStatus={setPhoneNumberValidationStatus}
                phoneNumberHelperText={phoneNumberHelperText}
                setPhoneNumberHelperText={setPhoneNumberHelperText}
                influencerPermissions={influencerPermissions}
                isActiveInfluencer={influencerPermissions?.isActiveInfluencer}
                setIsActiveInfluencer={setInfluencerActivity}
                setInfluencerClicksPermission={setInfluencerClicksPermission}
                setInfluencerSalesPermission={setInfluencerSalesPermission}
                setInfluencerRevenuePermission={setInfluencerRevenuePermission}
                setInfluencerIncludeVATPermission={setInfluencerIncludeVATPermission}
                shouldTestEmailValidationStatus={shouldTestEmailValidationStatus}
            />
        } else {
            return <EditInfluencerSection
                mode={mode}
                image={image}
                setImage={setInfluencerProfileImage}
                username={username}
                setUsername={setInfluencerUsername}
                setIsUsernameValid={setIsUsernameValid}
                email={email}
                originalEmail={originalEmail}
                setEmail={setInfluencerEmail}
                setIsEmailValid={setIsEmailValid}
                promocode={promocode}
                setPromocode={setInfluencerPromocode}
                commission={commission}
                setCommission={setInfluencerCommission}
                commissionValidationStatus={commissionValidationStatus}
                setCommissionValidationStatus={setCommissionValidationStatus}
                commissionHelperText={commissionHelperText}
                setCommissionHelperText={setCommissionHelperText}
                phoneNumber={phoneNumber}
                setPhoneNumber={setInfluencerPhoneNumber}
                phoneNumberValidationStatus={phoneNumberValidationStatus}
                setPhoneNumberValidationStatus={setPhoneNumberValidationStatus}
                phoneNumberHelperText={phoneNumberHelperText}
                setPhoneNumberHelperText={setPhoneNumberHelperText}
                influencerPermissions={influencerPermissions}
                isActiveInfluencer={influencerPermissions?.isActiveInfluencer}
                setIsActiveInfluencer={setInfluencerActivity}
                setInfluencerClicksPermission={setInfluencerClicksPermission}
                setInfluencerSalesPermission={setInfluencerSalesPermission}
                setInfluencerRevenuePermission={setInfluencerRevenuePermission}
                setInfluencerIncludeVATPermission={setInfluencerIncludeVATPermission}
            />
        }
    }

    function resetValidations() {
        setCommissionValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
        setCommissionHelperText('')
        setPhoneNumberValidationStatus(INPUT_VALIDATION_INDICATIONS.NO_INDICATION)
        setPhoneNumberHelperText('')
    }

    async function save() {
        resetValidations()
        setIsButtonProcessing(true)
        let imageToSave = image
        await uploadFile(image, null, url => {
            imageToSave = url
        }, () => {
            console.log('an error as occurred during file upload...')
        })

        if (!imageToSave.startsWith('blob')) {
            if (mode === VIEW_MODES.CREATE) {
                createInfluencer(username, email, imageToSave, promocode, commission === '' ? 0 : Number(commission), phoneNumber, influencerPermissions)
                    .then(newInfluencer => {
                        setObjectToSave(extractInfluencerData(newInfluencer))
                        setInfluencerPropertiesModified(false)
                        history.goBackStepsNoPerform()
                        setTimeout(() => {
                            history.goBack()
                        }, SEPERATE_GO_BACK_FOR_SAFARY_DELAY)
                        setTimeout(() => {
                            setRecentInfluencers([])
                            if (window.location.pathname !== INFLUENCERS_PAGE_URL) {
                                history.push(INFLUENCERS_PAGE_URL)
                            } else {
                                setFinishedCreatingObject(true)
                                setTimeout(() => {
                                    setFinishedCreatingObject(false)
                                }, 200)
                            }
                        }, CREATE_LINK_REDIRECT_TO_PAGE_DELAY)
                        // TODO create 1 context data to store all the loaded links, shorts and influencers
                    }).catch((error) => {
                        logger.error(`Error - create influencer failure - ${error}`)
                        showSaveDetailsErrorMessage()
                        setIsButtonProcessing(false)
                    })
            } else {
                updateInfluencer(username, email, imageToSave, promocode, commission === '' ? 0 : Number(commission), phoneNumber, influencerPermissions)
                    .then(updatedInfluencer => {
                        setObjectToSave(extractInfluencerData(updatedInfluencer))
                        showApprovalMessage(t('EDIT_INFLUENCER_SECTION_CHANGES_SAVED_APPROVAL_MESSAGE_TEXT'))
                        setIsButtonProcessing(false)
                        setInfluencerPropertiesModified(false)
                        history.goBackStepsNoPerform()
                        setFinishedUpdatingObject(true)
                        setTimeout(() => {
                            setFinishedUpdatingObject(false)
                        }, 200)
                    }).catch((error) => {
                        logger.error(`Error - edit influencer failure - ${error}`)
                        showSaveDetailsErrorMessage()
                        setIsButtonProcessing(false)
                    })
            }
        } else {
            setDialogState(prev => ({
                ...prev,
                isOpen: true,
                title: t('UPLOAD_FILE_ERROR_DIALOG_TITLE'),
                leftButtonText: t('UPLOAD_FILE_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
                rightButtonText: null,
                rightButtonClickHandler: () => { },
                isRightButtonWithLoader: false
            }))
            setIsButtonProcessing(false)
        }
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showSaveDetailsErrorMessage() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_TITLE'),
            leftButtonText: t('EDIT_LINK_PAGE_SAVE_DETAILS_ERROR_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonText: null,
            rightButtonClickHandler: () => { },
            isRightButtonWithLoader: false
        }))
    }

    function addDiscardChangesDialogBackHandler() {
        setTimeout(() => {
            history.addBackHandler(() => {
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_TITLE'),
                    message: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_MESSAGE'),
                    leftButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_LEFT_BUTTON_TEXT'),
                    rightButtonText: t('EDIT_INFLUENCER_SECTION_DISCARD_CHANGES_DIALOG_RIGHT_BUTTON_TEXT'),
                    leftButtonClickHandler: addDiscardChangesDialogBackHandler,
                    rightButtonClickHandler: () => {
                        endEditing()
                        history.goBack()
                    },
                    isRightButtonWithLoader: false
                }))
            })
        }, 300)
    }

    function goBack() {
        history.goBack()
    }

    function renderTopbarSaveButton() {
        switch (mode) {
            case VIEW_MODES.CREATE: return t('SHORT_VIEW_TOPBAR_CREATE_BUTTON_TEXT')
            case VIEW_MODES.EDIT: return t('SHORT_VIEW_TOPBAR_SAVE_BUTTON_TEXT')
            default: return ''
        }
    }

    return (
        <div className={isMobile ? 'mobile-influencer-view-container' : 'influencer-view-container'}>
            <ContentDialogTopbar
                title={getTitle()}
                onBackButtonClick={goBack}
                rightElement={<>
                    <LoaderButton
                        className='page-view-topbar-save-button'
                        buttonText={renderTopbarSaveButton()}
                        isLoading={isButtonProcessing}
                        loaderCircleColor='#0095f6'
                        isDisabled={!isAbleToSaveInfluencer || isButtonProcessing}
                        onClick={save}
                    />
                </>}
            />
            <div className={isMobile ? "mobile-influencer-view-content" : "influencer-view-content"}>
                {isLoadingInfluencer && <ContentDialogLoader />}
                {renderContent()}
            </div>
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={true}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={dialogState.isRightButtonWithLoader}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                onLeftButtonClick={dialogState.leftButtonClickHandler}
            />
        </div>
    )
}
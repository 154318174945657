import React, { useEffect, useRef, useState } from 'react'
import './ShopboardPage.css'
import { Trans, useTranslation } from 'react-i18next'
import ShopboardSocials from 'components/ShopboardSocials/ShopboardSocials'
import ImageBox from 'components/ImageBox/ImageBox'
import { INSTAGRAM_BLACK_WHITE_LOGO_URL, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX, NUMBER_TYPES, PAYDIN_DRAWER_DIRECTION, PAYDIN_DRAWER_TRANSITION_DURATION, PINTEREST_BLACK_WHITE_LOGO_URL, SHOPBOARD_PAGE_IMAGE, SHOPBOARD_POST_LIST_COUNT, SHOPBOARD_SOCIALS, SHOPBOARD_TAB_OPTIONS, TIKTOK_BLACK_WHITE_LOGO_URL } from 'constants/general.constants'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import { MobileView, isMobile } from 'react-device-detect'
import AnalyticsDataCard from 'components/AnalyticsDataCard/AnalyticsDataCard'
import { formatNumber } from 'utils/utils'
import { MdOutlineContentCopy } from 'react-icons/md'
import Tabs from 'components/Tabs/Tabs'
import ShopboardConnectComp from 'components/ShopboardConnectComp/ShopboardConnectComp'
import { extractShopboardSocialMediaPost } from 'utils/dataUtils'
import ShopboardSocialMediaPost from 'components/ShopboardSocialMediaPost/ShopboardSocialMediaPost'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import { getCurrencySymbol } from 'services/currency'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import { copyToClipboard, openUrl } from 'services/SystemService'
import { useHistoryContext } from 'contexts/History'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import MobileButtonStrip from 'components/MobileButtonStrip/MobileButtonStrip'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'
import LoaderPage from 'pages/LoaderPage/LoaderPage'
import Loader from 'components/Loader/Loader'
import SocialConnectButton from 'components/SocialConnectButton/SocialConnectButton'
import PaydinDrawer from 'components/PaydinDrawer/PaydinDrawer'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { RiShareBoxLine } from 'react-icons/ri'
import DrawerStyledButtonList from 'components/DrawerStyledButtonList/DrawerStyledButtonList'

const TAB_INDEX_SOCIAL = [SHOPBOARD_SOCIALS.INSTAGRAM, SHOPBOARD_SOCIALS.TIKTOK, SHOPBOARD_SOCIALS.PINTEREST]

export default function ShopboardPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        isShopboardConnected,
        checkShopboardStatus,
        isShopboardConnectedSyncing,
        isShopboardConnectedSynced,
        fetchShopboardLifeTimeAnalytics,
        fetchShopboardPosts,
        hasSocialMediaPosts,
        tagPostProducts,
        isFreeUserAndTrialEnded,
    } = useUserDetailsContext()

    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()

    const [selectedTabOptionIndex, setSelectedTabOptionIndex] = useState(0)
    const [tabContent, setTabContent] = useState(<></>)
    const [postListState, setPostListState] = useState({
        [SHOPBOARD_SOCIALS.INSTAGRAM]: userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.posts,
        [SHOPBOARD_SOCIALS.TIKTOK]: userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.posts,
        [SHOPBOARD_SOCIALS.PINTEREST]: userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.posts
    })
    const [tagProductsDialogState, setTagProductsDialogState] = useState({
        isOpen: false,
        selectedProducts: [],
        social: '',
        postId: ''
    })
    const [hasMoreDataState, setHasMoreDataState] = useState({
        [SHOPBOARD_SOCIALS.INSTAGRAM]: true,
        [SHOPBOARD_SOCIALS.TIKTOK]: true,
        [SHOPBOARD_SOCIALS.PINTEREST]: true
    })
    const [isLoadingMoreData, setIsLoadingMoreData] = useState({
        [SHOPBOARD_SOCIALS.INSTAGRAM]: false,
        [SHOPBOARD_SOCIALS.TIKTOK]: false,
        [SHOPBOARD_SOCIALS.PINTEREST]: false
    })
    const [approvalMessageState, setApprovalMessageState] = useState({
        isOpen: false,
        message: ''
    })
    const [isLoadingShopboardStatus, setIsLoadingShopboardStatus] = useState(true)
    const [isLoadingShopboardPosts, setIsLoadingShopboardPosts] = useState(false)
    const [optionsDrawerState, setOptionsDrawerState] = useState({
        isOpen: false,
        onOpen: openMoreOptionsDrawer,
        onClose: closeMoreOptionsDrawer,
        children: <></>
    })

    const shopboardContentRef = useRef(null)
    const noConnectionsContentTitlesRef = useRef(null)
    const postListRef = useRef(null)
    const topSectionRef = useRef(null)
    const analyticsRef = useRef(null)
    const tabsRef = useRef(null)
    const socialsRef = useRef(null)
    const intervalRef = useRef(null)

    useEffect(() => {
        if (!userDetails?.shopboard?.synced) {
            checkShopboardStatus(() => {
                setIsLoadingShopboardStatus(false)
            })
        } else {
            setIsLoadingShopboardStatus(false)
        }

        return () => {
            // clean interval for geting social media wating
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    useEffect(() => {
        if (isShopboardConnected() && !userDetails?.shopboard?.analytics?.synced) {
            fetchShopboardLifeTimeAnalytics()
        }

        if (userDetails?.shopboard?.synced && !intervalRef?.current && isShopboardConnected() && isShopboardConnectedSyncing()) {
            if (isShopboardConnected() && isShopboardConnectedSyncing()) {
                const socialMediasWaiting = getSyncingSocials()

                intervalRef.current = setInterval(socialMediasWaiting => {
                    checkShopboardStatus(() => {
                        if (!isShopboardConnected() || !isShopboardConnectedSyncing()) {
                            if (intervalRef?.current) {
                                clearInterval(intervalRef.current)
                            }
                        }

                        socialMediasWaiting.forEach(social => {
                            if (isShopboardConnectedSynced(social)) {
                                fetchPosts(social)
                            }
                        })
                    })
                }, 3 * 1000, socialMediasWaiting)
            }
        }

        return () => {
            // clean interval for geting social media waiting
            if (intervalRef.current && (!isShopboardConnected() || !isShopboardConnectedSyncing())) {
                clearInterval(intervalRef.current)
            }
        }
    }, [userDetails])

    useEffect(() => {
        // updating posts when updated for rendering
        if (isShopboardConnected() && isShopboardConnectedSynced()) {
            setPostListState({
                [SHOPBOARD_SOCIALS.INSTAGRAM]: userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.posts,
                [SHOPBOARD_SOCIALS.TIKTOK]: userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.posts,
                [SHOPBOARD_SOCIALS.PINTEREST]: userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.posts
            })
        }
    }, [userDetails])

    useEffect(() => {
        if (isShopboardConnected() && isShopboardConnectedSynced() && hasMoreDataState[TAB_INDEX_SOCIAL[selectedTabOptionIndex]]) {
            loadCurrentMediaPosts()
        }
    }, [selectedTabOptionIndex, userDetails, isLoadingMoreData])

    useEffect(() => {
        setTabContent(renderTabContent())
    }, [selectedTabOptionIndex, userDetails, isLoadingMoreData, postListState])

    function getSyncingSocials() {
        const syncingSocials = []
        Object.values(SHOPBOARD_SOCIALS).forEach(social => {
            if (isShopboardConnectedSyncing(social)) {
                syncingSocials.push(social)
            }
        })

        return syncingSocials
    }

    function fetchPosts(social, skip = 0, shouldOverridePostList = true, onSuccess = () => { }) {
        fetchShopboardPosts(social, SHOPBOARD_POST_LIST_COUNT, skip, shouldOverridePostList, posts => {
            const extractedPosts = posts.map(post => extractShopboardSocialMediaPost(post))
            const postsToDisplay = shouldOverridePostList ? extractedPosts : [...postListState[social], ...extractedPosts]
            setPostListState(prev => ({
                ...prev,
                [social]: postsToDisplay
            }))
            setHasMoreDataState(prev => ({
                ...prev,
                [social]: extractedPosts.length === SHOPBOARD_POST_LIST_COUNT
            }))
            onSuccess(postsToDisplay)
            setIsLoadingShopboardPosts(false)
        }, error => {
            setIsLoadingShopboardPosts(false)
        })
    }

    function loadCurrentMediaPosts() {
        if (isInstagramTabSelected() && isShopboardConnected(SHOPBOARD_SOCIALS.INSTAGRAM) && !isLoadingShopboardPosts && !isShopboardConnectedSyncing(SHOPBOARD_SOCIALS.INSTAGRAM) && !hasSocialMediaPosts(SHOPBOARD_SOCIALS.INSTAGRAM)) {
            setIsLoadingShopboardPosts(true)
            fetchPosts(SHOPBOARD_SOCIALS.INSTAGRAM)
        }
        if (isTiktokTabSelected() && isShopboardConnected(SHOPBOARD_SOCIALS.TIKTOK) && !isLoadingShopboardPosts && !isShopboardConnectedSyncing(SHOPBOARD_SOCIALS.TIKTOK) && !hasSocialMediaPosts(SHOPBOARD_SOCIALS.TIKTOK)) {
            setIsLoadingShopboardPosts(true)
            fetchPosts(SHOPBOARD_SOCIALS.TIKTOK)
        }

        if (isPinterestTabSelected() && isShopboardConnected(SHOPBOARD_SOCIALS.PINTEREST) && !isLoadingShopboardPosts && !isShopboardConnectedSyncing(SHOPBOARD_SOCIALS.PINTEREST) && !hasSocialMediaPosts(SHOPBOARD_SOCIALS.PINTEREST)) {
            setIsLoadingShopboardPosts(true)
            fetchPosts(SHOPBOARD_SOCIALS.PINTEREST)
        }
    }

    function isInstagramTabSelected() {
        return selectedTabOptionIndex === 0
    }

    function isTiktokTabSelected() {
        return selectedTabOptionIndex === 1
    }

    function isPinterestTabSelected() {
        return selectedTabOptionIndex === 2
    }

    function renderContent() {
        if (!userDetails?.shopboard?.synced) {
            return
        }
        if (isShopboardConnected()) {
            return renderConnectedContent()
        }
        return renderNoConnectionsContent()
    }

    function renderMobileContent() {
        if (!userDetails?.shopboard?.synced) {
            return
        }

        if (isShopboardConnected()) {
            return renderMobileConnectedContent()
        }

        return renderMobileNoConnectionsContent()
    }

    function renderMobileConnectedContent() {
        return <div className="mobile-shopboard-page-content-connected">
            <MobileButtonStrip
                hasBottomLine={true}
                length={3}
                paddingInline='0px'
                stripButtonTextSize='14px'
                isFixed={true}
                top='var(--content-dialog-topbar-height)'
                buttons={[
                    {
                        text: t('SHOPBOARD_PAGE_INSTAGRAM_TAB_TEXT'),
                        image: <ImageBox
                            className='mobile-dashboard-topbar-button-image'
                            image={INSTAGRAM_BLACK_WHITE_LOGO_URL}
                        />,
                        onClick: () => setSelectedTabOptionIndex(0),
                        isSelected: isInstagramTabSelected(),
                        isDisabled: false
                    },
                    {
                        text: t('SHOPBOARD_PAGE_TIKTOK_TAB_TEXT'),
                        image: <ImageBox
                            className='mobile-dashboard-topbar-button-image'
                            image={TIKTOK_BLACK_WHITE_LOGO_URL}
                        />,
                        onClick: () => setSelectedTabOptionIndex(1),
                        isSelected: isTiktokTabSelected(),
                        isDisabled: false
                    },
                    {
                        text: t('SHOPBOARD_PAGE_PINTEREST_TAB_TEXT'),
                        image: <ImageBox
                            className='mobile-dashboard-topbar-button-image'
                            image={PINTEREST_BLACK_WHITE_LOGO_URL}
                        />,
                        onClick: () => setSelectedTabOptionIndex(2),
                        isSelected: isPinterestTabSelected(),
                        isDisabled: false
                    }
                ]}
            />
            <div className={`mobile-shopboard-page-content-connected-tab-content ${!isShopboardConnected(getCurrentlySelectedSocial()) || isShopboardConnectedSyncing(getCurrentlySelectedSocial()) ? 'no-list' : ''}`} onScroll={handlePostsScroll}>
                {(isLoadingShopboardPosts && isShopboardConnected(getCurrentlySelectedSocial()) && isShopboardConnectedSynced(getCurrentlySelectedSocial())) && <LoaderPage isFullScreen={false} isContained={true} styles={{ marginTop: '110px' }} />}
                {tabContent}
            </div>
        </div>
    }

    function renderConnectedContent() {
        return <div className="shopboard-page-content-connected">
            <Tabs
                selectedTabOptionIndex={selectedTabOptionIndex}
                setSelectedTabOptionIndex={setSelectedTabOptionIndex}
                tabs={SHOPBOARD_TAB_OPTIONS}
                hasTopLine={false}
                selectedBottomLine={true}
                optionPadding={'5px'}
                isCentered={false}
                inputRef={tabsRef}
            />
            <div className="shopboard-page-content-connected-tab-content">
                {/* TODO - Consider using Loader instead of LoaderPage */}
                {(isLoadingShopboardPosts && isShopboardConnected(getCurrentlySelectedSocial()) && isShopboardConnectedSynced(getCurrentlySelectedSocial())) && <LoaderPage isFullScreen={false} isContained={true} styles={{ marginTop: '40px', height: '90%' }} />}
                {tabContent}
            </div>
        </div>
    }

    function renderTabContent() {
        switch (true) {
            case isInstagramTabSelected(): {
                if (!isShopboardConnected(SHOPBOARD_SOCIALS.INSTAGRAM)) {
                    return <div className='shopboard-page-content-connected-tab-content-connected'>
                        <ShopboardConnectComp social={SHOPBOARD_SOCIALS.INSTAGRAM} />
                    </div>
                }

                if (isShopboardConnectedSynced(SHOPBOARD_SOCIALS.INSTAGRAM)) {
                    return <>
                        <div ref={postListRef} className={isMobile ? "mobile-shopboard-page-content-connected-tab-content-list-container" : "shopboard-page-content-connected-tab-content-list-container"}>
                            {postListState[SHOPBOARD_SOCIALS.INSTAGRAM]?.map(post => <ShopboardSocialMediaPost
                                key={post?.id}
                                id={post?.id}
                                social={SHOPBOARD_SOCIALS.INSTAGRAM}
                                image={post?.imageUrl}
                                isVisible={post?.isVisible}
                                taggedProducts={post?.products}
                                onTagProducts={openAddProductsDialog}
                            />)}
                        </div>
                        {isLoadingMoreData[SHOPBOARD_SOCIALS.INSTAGRAM] && renderLoaderRow()}
                    </>
                }
                return renderConnectedSyncingTabContent()
            }

            case isTiktokTabSelected(): {
                if (!isShopboardConnected(SHOPBOARD_SOCIALS.TIKTOK)) {
                    return <div className='shopboard-page-content-connected-tab-content-connected'>
                        <ShopboardConnectComp social={SHOPBOARD_SOCIALS.TIKTOK} />
                    </div>
                }

                if (isShopboardConnectedSynced(SHOPBOARD_SOCIALS.TIKTOK)) {
                    return <>
                        <div ref={postListRef} className={isMobile ? "mobile-shopboard-page-content-connected-tab-content-list-container" : "shopboard-page-content-connected-tab-content-list-container"}>
                            {postListState[SHOPBOARD_SOCIALS.TIKTOK]?.map(post => <ShopboardSocialMediaPost
                                key={post?.id}
                                id={post?.id}
                                social={SHOPBOARD_SOCIALS.TIKTOK}
                                image={post?.imageUrl}
                                isVisible={post?.isVisible}
                                taggedProducts={post?.products}
                                onTagProducts={openAddProductsDialog}
                            />)}
                        </div>
                        {isLoadingMoreData[SHOPBOARD_SOCIALS.TIKTOK] && renderLoaderRow()}
                    </>
                }
                return renderConnectedSyncingTabContent()
            }

            default: { // pinterest is selected
                if (!isShopboardConnected(SHOPBOARD_SOCIALS.PINTEREST)) {
                    return <div className='shopboard-page-content-connected-tab-content-connected'>
                        <ShopboardConnectComp social={SHOPBOARD_SOCIALS.PINTEREST} />
                    </div>
                }

                if (isShopboardConnectedSynced(SHOPBOARD_SOCIALS.PINTEREST)) {
                    return <>
                        <div ref={postListRef} className={isMobile ? "mobile-shopboard-page-content-connected-tab-content-list-container" : "shopboard-page-content-connected-tab-content-list-container"}>
                            {postListState[SHOPBOARD_SOCIALS.PINTEREST]?.map(post => <ShopboardSocialMediaPost
                                key={post?.id}
                                id={post?.id}
                                social={SHOPBOARD_SOCIALS.PINTEREST}
                                image={post?.imageUrl}
                                isVisible={post?.isVisible}
                                taggedProducts={post?.products}
                                onTagProducts={openAddProductsDialog}
                            />)}
                        </div>
                        {isLoadingMoreData[SHOPBOARD_SOCIALS.PINTEREST] && renderLoaderRow()}
                    </>
                }
                return renderConnectedSyncingTabContent()
            }
        }
    }

    function renderLoaderRow() {
        return <div className={isMobile ? 'mobile-shopboard-page-loader-row' : 'shopboard-page-loader-row'}>
            <Loader styles={{
                width: isMobile ? '25px' : '30px',
                height: isMobile ? '25px' : '30px'
            }} />
        </div>
    }

    function openAddProductsDialog(social, postId, products) {
        setTagProductsDialogState({
            isOpen: true,
            social,
            postId,
            selectedProducts: products
        })
    }

    function tagProducts(selectedProducts) {
        tagPostProducts(tagProductsDialogState.social, tagProductsDialogState.postId, selectedProducts)
    }

    function renderConnectedSyncingTabContent() {
        return <div className="shopboard-page-content-connected-tab-content-syncing">
            <div className="shopboard-page-content-connected-tab-content-syncing-titles-container">
                <div className="shopboard-page-content-connected-tab-content-syncing-title">{t('SHOPBOARD_PAGE_CONTENT_CONNECTED_SYNCING_TITLE')}</div>
                <div className="shopboard-page-content-connected-tab-content-syncing-subtitle">{t('SHOPBOARD_PAGE_CONTENT_CONNECTED_SYNCING_SUBTITLE')}</div>
            </div>
            <div className="shopboard-page-content-no-connections-image">
                <ImageBox
                    image={SHOPBOARD_PAGE_IMAGE}
                    className='shopboard-page-content-image'
                />
            </div>
        </div>
    }

    function closeAddProductDialog() {
        setTagProductsDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function setSelectedProducts(products) {
        setTagProductsDialogState(prev => ({
            ...prev,
            selectedProducts: products
        }))
    }

    function renderMobileNoConnectionsContent() {
        return <div className='mobile-shopboard-page-content-no-connections'>
            <div ref={noConnectionsContentTitlesRef} className="mobile-shopboard-page-content-no-connections-titles">
                <div className="mobile-shopboard-page-content-no-connections-title">{t('SHOPBOARD_PAGE_CONTENT_NO_CONNECTIONS_TITLE')}</div>
                <div className="mobile-shopboard-page-content-no-connections-subtitle">
                    {
                        <Trans
                            i18nKey='SHOPBOARD_PAGE_CONTENT_NO_CONNECTIONS_SUBTITLE'
                            components={{
                                a: <div className='superscript' />
                            }}
                        />
                    }
                </div>
            </div>
            <div ref={socialsRef} className="mobile-shopboard-page-content-no-connections-socials">
                <ShopboardSocials
                    instagramPostsCount={!isShopboardConnected(SHOPBOARD_SOCIALS.INSTAGRAM) ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.numberOfPosts}
                    tiktokPostsCount={!isShopboardConnected(SHOPBOARD_SOCIALS.TIKTOK) ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.numberOfPosts}
                    pinterestPostsCount={!isShopboardConnected(SHOPBOARD_SOCIALS.PINTEREST) ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.numberOfPosts}
                    showImage={false}
                />
            </div>
            <div className="mobile-shopboard-page-content-no-connections-image">
                <ImageBox
                    image={SHOPBOARD_PAGE_IMAGE}
                    className='shopboard-page-content-image'
                />
            </div>
        </div>
    }

    function renderNoConnectionsContent() {
        return <div className='shopboard-page-content-no-connections'>
            <div ref={noConnectionsContentTitlesRef} className="shopboard-page-content-no-connections-titles">
                <div className="shopboard-page-content-no-connections-title">{t('SHOPBOARD_PAGE_CONTENT_NO_CONNECTIONS_TITLE')}</div>
                <div className="shopboard-page-content-no-connections-subtitle">
                    {
                        <Trans
                            i18nKey='SHOPBOARD_PAGE_CONTENT_NO_CONNECTIONS_SUBTITLE'
                            components={{
                                a: <div className='superscript' />
                            }}
                        />
                    }
                </div>
            </div>
            <div className="shopboard-page-content-no-connections-image-container">
                <div className="shopboard-page-content-no-connections-connect-buttons">
                    <SocialConnectButton social={SHOPBOARD_SOCIALS.INSTAGRAM} />
                    <SocialConnectButton social={SHOPBOARD_SOCIALS.TIKTOK} />
                    <SocialConnectButton social={SHOPBOARD_SOCIALS.PINTEREST} />
                </div>
                <ImageBox
                    image={SHOPBOARD_PAGE_IMAGE}
                    className='shopboard-page-content-image'
                />
            </div>
        </div>
    }

    function handlePostsScroll(e) {
        if (((!isMobile && isShopboardConnected()) || (isMobile && isShopboardConnected(getCurrentlySelectedSocial()))) && isShopboardConnectedSynced(getCurrentlySelectedSocial())) {
            const scrollPosition = e.target.scrollTop
            const thresholdScrollHeight = postListRef?.current?.clientHeight - ((isMobile ? 300 : 400) * 1) - 25 - (isMobile ? 40 + 70 : tabsRef?.current?.clientHeight + 20 + 10 + analyticsRef?.current?.clientHeight + 30 + topSectionRef?.current?.clientHeight + 40) - (isMobile ? 0.4 * window.innerHeight : 0)

            if (!isLoadingMoreData[getCurrentlySelectedSocial()] && hasMoreDataState[getCurrentlySelectedSocial()] && scrollPosition >= thresholdScrollHeight) {
                setIsLoadingMoreData(prev => ({
                    ...prev,
                    [getCurrentlySelectedSocial()]: true
                }))
                fetchPosts(getCurrentlySelectedSocial(), userDetails?.shopboard?.socialMedias[getCurrentlySelectedSocial()]?.posts.length, false, extractedPosts => {
                    setIsLoadingMoreData(prev => ({
                        ...prev,
                        [getCurrentlySelectedSocial()]: false
                    }))
                })
            }
        }
    }

    function getCurrentlySelectedSocial() {
        switch (selectedTabOptionIndex) {
            case 0: return SHOPBOARD_SOCIALS.INSTAGRAM
            case 1: return SHOPBOARD_SOCIALS.TIKTOK
            default: return SHOPBOARD_SOCIALS.PINTEREST
        }
    }

    async function copyShopboardLink() {
        if (isMobile) {
            history.goBack()

            setTimeout(async () => {
                await copyToClipboard(userDetails?.shopboard?.url)
                showApprovalMessage(t('SHOPBOARD_PAGE_APPROVAL_MESSAGE_SHOPBOARD_LINK_COPIED'))
            }, PAYDIN_DRAWER_TRANSITION_DURATION)
        } else {
            await copyToClipboard(userDetails?.shopboard?.url)
            showApprovalMessage(t('SHOPBOARD_PAGE_APPROVAL_MESSAGE_SHOPBOARD_LINK_COPIED'))
        }
    }

    function showApprovalMessage(message) {
        setApprovalMessageState({
            isOpen: true,
            message
        })
    }

    function closeMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function openMoreOptionsDrawer() {
        setOptionsDrawerState(prev => ({
            ...prev,
            isOpen: true,
            onClose: closeMoreOptionsDrawer,
            children: <>
                <DrawerStyledButtonList
                    buttons={[
                        {
                            text: t('SHOPBOARD_PAGE_MORE_OPTIONS_DRAWER_OPEN_LINK_OPTION'),
                            image: <RiShareBoxLine className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: () => openUrl(userDetails?.shopboard?.url)
                        },
                        {
                            text: t('SHOPBOARD_PAGE_MORE_OPTIONS_DRAWER_COPY_OPTION'),
                            image: <MdOutlineContentCopy className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: copyShopboardLink
                        },
                        {
                            text: t('MOBILE_DASHBOARD_TOPBAR_MORE_OPTIONS_DRAWER_OPTION_CANCEL'),
                            image: <AiOutlineClose className='mobile-dashboard-topbar-more-options-drawer-option-image' />,
                            onClick: () => history.goBack()
                        }
                    ]}
                />
            </>
        }))
    }

    return (
        <div className={isMobile ? 'mobile-shopboard-page-container' : 'shopboard-page-container'} onScroll={!isMobile ? handlePostsScroll : null}>
            {isLoadingShopboardStatus && <DashboardLoader />}
            {
                isMobile ? <>
                    <ContentDialogTopbar
                        title={t('MOBILE_SHOPBOARD_PAGE_TITLE')}
                        onBackButtonClick={() => history.goBack()}
                        rightElement={<>
                            <BiDotsHorizontalRounded className='mobile-shopboard-page-more-options-button' onClick={openMoreOptionsDrawer} />
                        </>}
                    />
                    <div ref={shopboardContentRef} className="mobile-shopboard-page-content">{renderMobileContent()}</div>
                </> : <>
                    <div ref={topSectionRef} className="shopboard-page-top-section">
                        <div className="shopboard-page-title-container">
                            <div className="shopboard-page-title">
                                <Trans
                                    i18nKey='SHOPBOARD_PAGE_TITLE'
                                    components={{
                                        a: <div className='superscript' />
                                    }}
                                />
                            </div>
                            {
                                isShopboardConnected() && <div className="shopboard-page-link-url-field">
                                    <a href={userDetails?.shopboard?.url} target='_blank' className="shopboard-page-link-url-field-text">{userDetails?.shopboard?.url}</a>
                                    <MdOutlineContentCopy className="shopboard-page-link-url-field-image" onClick={copyShopboardLink} />
                                </div>
                            }
                        </div>
                        {
                            isShopboardConnected() && <ShopboardSocials
                                instagramPostsCount={!userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.connected ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.numberOfPosts}
                                instagramUsername={!userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.connected ? "" : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.username}
                                tiktokPostsCount={!userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.connected ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.numberOfPosts}
                                tiktokUsername={!userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.connected ? "" : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.username}
                                pinterestPostsCount={!userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.connected ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.numberOfPosts}
                                pinterestUsername={!userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.connected ? "" : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.username}
                            />
                        }
                    </div>
                    <div ref={shopboardContentRef} className="shopboard-page-content" style={{ paddingBottom: (!isShopboardConnected() && !isMobile) && '20px' }}>
                        {
                            (isShopboardConnected() && !isMobile) && <div ref={analyticsRef} className='shopboard-page-analytics'>
                                {userDetails?.permissions?.clicksEnabled && <AnalyticsDataCard
                                    title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_VISITS')}
                                    value={typeof userDetails?.shopboard?.analytics?.visits === 'number' ? formatNumber(userDetails?.shopboard?.analytics?.visits, NUMBER_TYPES.SHORT) : userDetails?.shopboard?.analytics?.visits}
                                    tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_VISITS_TOOLTIP_TEXT')}
                                    numberStyles={{ fontSize: '24px' }}
                                />}
                                {userDetails?.permissions?.salesEnabled && <AnalyticsDataCard
                                    title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_COMPLETED_CHECKOUTS')}
                                    value={typeof userDetails?.shopboard?.analytics?.sales === 'number' ? formatNumber(userDetails?.shopboard?.analytics?.sales, NUMBER_TYPES.SHORT) : userDetails?.shopboard?.analytics?.sales}
                                    tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_COMPLETED_CHECKOUTS_TOOLTIP_TEXT')}
                                    numberStyles={{ fontSize: '24px' }}
                                />}
                                {(userDetails?.permissions?.salesEnabled && userDetails?.permissions?.clicksEnabled) && <AnalyticsDataCard
                                    title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_CR')}
                                    value={typeof userDetails?.shopboard?.analytics?.cr === 'number' ? `${formatNumber(userDetails?.shopboard?.analytics?.cr, NUMBER_TYPES.PERCENTAGE)}%` : userDetails?.shopboard?.analytics?.cr}
                                    tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_CR_TOOLTIP_TEXT')}
                                    isTooltipHorizontalSwap={isMobile}
                                    numberStyles={{ fontSize: '24px' }}
                                />}
                                {userDetails?.permissions?.revenueEnabled && <AnalyticsDataCard
                                    title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REVENUE')}
                                    value={typeof userDetails?.shopboard?.analytics?.revenue === 'number' ? `${getCurrencySymbol(userDetails?.shopboard?.analytics?.currency)}${formatNumber(userDetails?.shopboard?.analytics?.revenue, NUMBER_TYPES.SHORT)}` : userDetails?.shopboard?.analytics?.revenue}
                                    tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_REVENUE_TOOLTIP_TEXT')}
                                    numberStyles={{ fontSize: '24px' }}
                                />}
                                {userDetails?.permissions?.revenueEnabled && <AnalyticsDataCard
                                    isTooltipHorizontalSwap={true}
                                    title={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_AOV')}
                                    value={typeof userDetails?.shopboard?.analytics?.revenue === 'number' && typeof userDetails?.shopboard?.analytics?.sales === 'number' ? `${getCurrencySymbol(userDetails?.shopboard?.analytics?.currency)}${formatNumber(userDetails?.shopboard?.analytics?.revenue / userDetails?.shopboard?.analytics?.sales, NUMBER_TYPES.SHORT, true)}` : '-'}
                                    tooltipText={t('ANALYTICS_PAGE_OVERVIEW_SECTION_FRAME_AOV_TOOLTIP_TEXT')}
                                    numberStyles={{ fontSize: '24px' }}
                                />}
                            </div>
                        }
                        <div className="shopboard-page-content-body" style={{ padding: !isShopboardConnected() && '30px 40px 0px 40px' }}>{renderContent()}</div>
                    </div>
                </>
            }
            <AddProductsDialog
                isDialogOpen={tagProductsDialogState.isOpen}
                handleDialogClose={closeAddProductDialog}
                selectedProducts={tagProductsDialogState.selectedProducts}
                setSelectedProducts={setSelectedProducts}
                onFinishProductsSelection={tagProducts}
                selectMaxLimit={5}
                resetSelectedOnFinish={true}
            />
            <ApprovalMessage
                isMessageShown={approvalMessageState.isOpen}
                closeMessage={() => setApprovalMessageState(prev => ({ ...prev, isOpen: false }))}
                text={approvalMessageState.message}
            />

            <MobileView>
                <PaydinDrawer
                    direction={PAYDIN_DRAWER_DIRECTION.BOTTOM}
                    isOpen={optionsDrawerState.isOpen}
                    onOpen={optionsDrawerState.onOpen}
                    onClose={optionsDrawerState.onClose}
                    hasPadding={true}
                    topRightBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                    topLeftBorderRadiusPx={LINKS_PAGE_MOBILE_OPTIONS_DRAWER_TOP_BORDER_RADIUS_PX}
                >
                    {optionsDrawerState.children}
                </PaydinDrawer>
            </MobileView>
        </div>
    )
}
import React from 'react'
import './AddProductsDialogProduct.css'
import { ADD_PRODUCTS_DIALOG_PRODUCT_STATUS } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import { BsCheck } from 'react-icons/bs'
import ImageBox from 'components/ImageBox/ImageBox'
import MultiLineText from 'components/MultiLineText/MultiLineText'

/**
 * Represents a product list item inside {@link AddProductsDialog} component.
 * @param {object} data - The data of this product
 * @param {boolean} isItemSelected - Determins whether this item is selected or not
 * @param {function} onItemClick - A function to perfomr whenever this item is clicked
 */
export default function AddProductsDialogProduct({
    elementRef,
    data = {},
    isItemSelected = false,
    onItemClick = () => { }
}) {
    const { t } = useTranslation()

    return (
        <div className='add-products-dialog-product-container' onClick={onItemClick} ref={elementRef}>
            <div className='add-products-dialog-product-image-frame'>
                <ImageBox
                    className='add-products-dialog-product-image'
                    image={data?.imageUrl}
                    isImageFitCover={true}
                    showGreyImageBackground={true}
                />
                {isItemSelected && <div className='selected-item-mask'>
                    <BsCheck className='add-products-dialog-product-selected-v-mark' />
                </div>}
                <div className="add-products-dialog-product-tags">
                    {data?.productStatus === ADD_PRODUCTS_DIALOG_PRODUCT_STATUS.DRAFT && <div className="add-products-dialog-product-draft-tag">{t('ADD_PRODUCTS_DIALOG_DRAFT_PRODUCT_TAG_TEXT')}</div>}
                    {data?.hasNoQuantity && <div className="add-products-dialog-product-out-of-stock-tag">{t('ADD_PRODUCTS_DIALOG_OUT_OF_STOCK_PRODUCT_TAG_TEXT')}</div>}
                </div>
            </div>
            <div className='add-products-dialog-product-details'>
                <MultiLineText
                    text={data?.title}
                    className='add-products-dialog-product-title'
                    lines={2}
                />
            </div>
        </div>
    )
}
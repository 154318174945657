import PaydinPieChart from 'components/PaydinPieChart/PaydinPieChart'
import React from 'react'
import { getCurrencySymbol } from 'services/currency'
import './SalesBreakdownChart.css'
import Loader from 'components/Loader/Loader'
import { formatNumber } from 'utils/utils'
import { NUMBER_TYPES } from 'constants/general.constants'

/**
 * Represents the sales breakdown chart for a specific influencer.
 * @param {number} linksRevenue - The links revenue of the influencer
 * @param {number} shortsRevenue - The shorts revenue of the influencer
 * @param {number} organicRevenue - The externals revenue of the influencer
 * @param {number} linksColor - The marking color for the links' data
 * @param {number} shortsColor - The marking color for the shorts' data
 * @param {number} organicColor - The marking color for the externals' data
 * @param {number} currency - The required currency to display
 * @param {number} dimens - The dimensions of the sales breakdown pie chart
 * @param {number} isLoadingData - Determins whether the influencer's data is being loaded or not
 */
export default function SalesBreakdownChart({
    linksRevenue,
    shortsRevenue,
    organicRevenue,
    linksColor,
    shortsColor,
    organicColor,
    currency,
    dimens = 400,
    isLoadingData = true
}) {
    function normalizeChartData() {
        if ([null, undefined].includes(linksRevenue) || [null, undefined].includes(shortsRevenue) || [null, undefined].includes(organicRevenue)) {
            return []
        }

        const dataArray = [
            {
                name: 'Links',
                value: linksRevenue,
                color: linksColor
            },
            {
                name: 'Shorts',
                value: shortsRevenue,
                color: shortsColor
            },
            {
                name: 'Organic',
                value: organicRevenue,
                color: organicColor
            },
        ]

        return dataArray
    }

    function renderPieValue(value) {
        return `${getCurrencySymbol(currency)}${formatNumber(value, NUMBER_TYPES.SHORT)}`
    }

    return (
        <div className="sales-breakdown-chart">
            {
                !isLoadingData ? <PaydinPieChart
                    chartData={normalizeChartData()}
                    pieValue={renderPieValue}
                    dimens={dimens}
                /> : <div className='sales-breakdown-chart-loader-container' style={{ minWidth: dimens, minHeight: dimens }}>
                    <Loader styles={{ width: '25px', height: '25px', position: 'absolute', inset: 0, margin: 'auto' }} />
                </div>
            }
        </div>
    )
}

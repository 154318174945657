import React from 'react'
import './ShopifyConnectStore.css'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import BoxedMessage from 'components/BoxedMessage/BoxedMessage'
import { BOXED_MESSAGE_TYPES, SHOPIFY_LOGO_URL } from 'constants/general.constants'

/**
 * Represents a portion of the Shopify store connect interface.
 * It displays the Shopify store provider and allows input for Shopify store url with a connection button.
 * @param {boolean} isConnectingStore - Determins whether the store connection request is in progress or not
 * @param {boolean} isShopifyConnectionFail - Determins whether the store connection request has failed or not
 * @param {function} connectShopifyStoreFunction - A callback function to perform when clicking on the store connection button
 */
export default function ShopifyConnectStore({
    isConnectingStore = false,
    isShopifyConnectionFail = false,
    connectShopifyStoreFunction = () => { }
}) {
    const { t } = useTranslation()

    return (
        <>
            <div className="shopify-connect-store-provider-section">
                <div className="shopify-connect-store-provider">
                    <ImageBox
                        className="shopify-connect-store-provider-image-container"
                        imageClassName="shopify-connect-store-provider-image"
                        image={SHOPIFY_LOGO_URL} />
                    <div className="shopify-connect-store-provider-name">{t('SHOPIFY_CONNECT_STORE_PROVIDER_NAME')}</div>
                </div>
            </div>
            {
                isShopifyConnectionFail && <BoxedMessage
                    type={BOXED_MESSAGE_TYPES.ERROR}
                    message={t('SHOPIFY_CONNECT_CONNECTION_FAILED_ERROR_MESSAGE')}
                />
            }
            <div className="shopify-connect-buttons-box">
                {/* TODO - shopify review not allowing myshopify url so changed to redirect to shopify app store */}
                <LoaderButton
                    className="shopify-connect-connect-shopify-store-button shopify-connect-button"
                    buttonText={"Install the payli app on Shopify app store"}
                    isLoading={isConnectingStore}
                    renderAsButton={false}
                    onClick={() => connectShopifyStoreFunction()} />
            </div>
        </>
    )
}
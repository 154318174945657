import React from 'react'
import './BaseTopBar.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { PAYLINK_LOGO_URL } from 'constants/general.constants'
import { isMobile } from 'react-device-detect'


/**
 * Represents a base topbar with a logo.
 */
export default function BaseTopBar() {
    return (
        <div className={`${isMobile ? 'mobile-base-topbar-container' : 'base-topbar-container'}`}>
            <ImageBox
                image={PAYLINK_LOGO_URL}
                className='base-topbar-logo-image'
            />
        </div>
    )
}
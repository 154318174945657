import React, { useEffect, useState } from 'react'
import './AccountPage.css'
import { useTranslation } from 'react-i18next'
import AccountSection from 'components/AccountSection/AccountSection'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { useHistoryContext } from 'contexts/History'
import { isMobile } from 'react-device-detect'
import { useAuthContext, useUserDetailsContext } from 'contexts/User'
import ShopifyConnectStore from 'components/ShopifyConnectStore/ShopifyConnectStore'
import { ACCOUNT_PAGE_EMAIL_INPUT_NAME, SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR, SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX, SHOPIFY_LOGO_URL, ACCOUNT_PAGE_USERNAME_INPUT_NAME, INSTAGRAM_LOGO_URL, PINTEREST_LOGO_URL, TIKTOK_LOGO_URL, SHOPBOARD_SOCIALS, PRICING_PLAN_DATA } from 'constants/general.constants'
import { ConnectStoreAPI, DisconnectStore, SyncProducts } from 'api/store/store'
import { LINKS_PAGE_URL, PRICING_PLANS_PAGE_URL, APPEARANCE_PAGE_URL, HOME_PAGE_URL } from 'constants/routes.constants'
import { ResetUserPassword, UpdateUserInfo } from 'api/auth/auth'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import ImageBox from 'components/ImageBox/ImageBox'
import ApprovalMessage from 'components/ApprovalMessage/ApprovalMessage'
import SettingsInput from 'components/SettingsInput/SettingsInput'
import ConfirmDisconnectDialog from 'dialogs/ConfirmDisconnectDialog/ConfirmDisconnectDialog'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import ShopboardSocialStatus from 'components/ShopboardSocialStatus/ShopboardSocialStatus'

/**
 * Represents the account page.
 */
export default function AccountPage() {
    const { t } = useTranslation()
    const history = useHistoryContext()
    const {
        userDetails,
        hasShopifyStore,
        shopboardDisconnect,
        isShopboardConnected,
        checkShopboardStatus
    } = useUserDetailsContext()
    const { verify } = useAuthContext()

    const [inputState, setInputState] = useState({
        name: userDetails.name,
        email: userDetails.email
    })

    const [isConnectingStore, setIsConnectingStore] = useState(false)
    const [isShopifyConnectionFail, setIsShopifyConnectionFail] = useState(false)
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        handleDialogClose: closeDialog,
        title: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [buttonsLoadingState, setButtonsLoadingState] = useState({
        saveAnalyticsIntegrations: false,
        saveUtmParams: false,
        saveShippingAndReturnUrl: false,
        disconnect: false,
        forceProductSync: false,
        resetPassword: false,
        deleteAccount: false,
        instagramDisconnect: false,
        tiktokDisconnect: false,
        pinterestDisconnect: false
    })
    const [approvalMessageState, setApprovalMessageState] = useState({
        isMessageShown: false,
        text: t('')
    })
    const [isConfirmDisconnectDialogOpen, setIsConfirmDisconnectDialogOpen] = useState(false)

    useEffect(() => {
        if (!userDetails?.shopboard?.synced) {
            checkShopboardStatus()
        }
    }, [])

    function connectShopifyStore(shopUrlPrefix) {
        window.open("https://apps.shopify.com/paylink", "_blank", "noreferrer")
        // TODO - shopify review don't allow requesting myshopify url. So for now redirecting to app store.
        // setIsConnectingStore(true)
        // ConnectStoreAPI(shopUrlPrefix + SHOPIFY_CONNECT_SHOPIFY_SHOP_URL_SUFFIX)
        //     .then(redirectUrl => {
        //         setIsConnectingStore(false)
        //         window.open(redirectUrl, "_blank", "noreferrer")
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         setIsConnectingStore(false)
        //         setIsShopifyConnectionFail(true)
        //     })
    }

    function hideApprovalMassage() {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: false
        }))
    }

    function showApprovalMessage(text) {
        setApprovalMessageState(prev => ({
            ...prev,
            isMessageShown: true,
            text
        }))
    }

    function upgradePlan() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    function closeConfirmDisconnectDialog() {
        setIsConfirmDisconnectDialogOpen(false)
    }

    function openDisconnectConfirmDialog() {
        setIsConfirmDisconnectDialogOpen(true)
    }

    function disconnect() {
        setButtonsLoadingState(prev => ({
            ...prev,
            disconnect: true
        }))
        DisconnectStore(userDetails?.businessId)
            .then(response => {
                verify(data => {
                    history.push(HOME_PAGE_URL)
                }, error => {
                    setButtonsLoadingState(prev => ({
                        ...prev,
                        disconnect: false
                    }))
                    console.log(error)
                })
            })
            .catch(error => {
                setButtonsLoadingState(prev => ({
                    ...prev,
                    disconnect: false
                }))
                console.log(error)
            })
    }

    function syncProducts() {
        setButtonsLoadingState(prev => ({
            ...prev,
            forceProductSync: true
        }))
        SyncProducts(userDetails?.businessId)
            .then(response => {
                if (response.status) {
                    showApprovalMessage(t('ACCOUNT_PAGE_PRODUCTS_SYNCED_APPROVAL_MESSAGE_TEXT'))
                } else {
                    showApprovalMessage(t('ACCOUNT_PAGE_PRODUCTS_SYNCED_ERROR_MESSAGE_TEXT'))
                }
                setButtonsLoadingState(prev => ({
                    ...prev,
                    forceProductSync: false
                }))
            })
            .catch(error => {
                showApprovalMessage(t('ACCOUNT_PAGE_PRODUCTS_SYNCED_APPROVAL_MESSAGE_TEXT'))
                setButtonsLoadingState(prev => ({
                    ...prev,
                    forceProductSync: false
                }))
            })
    }

    function resetPassword() {
        setButtonsLoadingState(prev => ({
            ...prev,
            resetPassword: true
        }))
        ResetUserPassword()
            .then(response => {
                setButtonsLoadingState(prev => ({
                    ...prev,
                    resetPassword: false
                }))
                setDialogState(prev => ({
                    ...prev,
                    isOpen: true,
                    title: t('RESET_PASSWORD_EMAIL_SENT_DIALOG_TITLE'),
                    rightButtonText: null,
                    leftButtonText: t('ACCOUNT_PAGE_RESET_PASSWORD_EMAIL_SENT_DIALOG_LEFT_BUTTON_TEXT')
                }))
            })
            .catch(error => {
                setButtonsLoadingState(prev => ({
                    ...prev,
                    resetPassword: false
                }))
                console.log(error)
            })
    }

    function deleteAccount() {
        // TODO add delete account logic
        console.log('deleting account...')
    }

    function onDeleteAccountButtonClick() {
        setDialogState(prev => ({
            ...prev,
            isOpen: true,
            title: t('ACCOUNT_PAGE_DELETE_ACCOUNT_CONFIRMATION_DIALOG_TITLE'),
            leftButtonText: t('ACCOUNT_PAGE_DELETE_ACCOUNT_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
            rightButtonText: t('ACCOUNT_PAGE_DELETE_ACCOUNT_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
            rightButtonClickHandler: deleteAccount,
            dialogLayout: null
        }))
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function handleInputChange(e) {
        switch (e.target.name) {
            case ACCOUNT_PAGE_EMAIL_INPUT_NAME:
                setInputState(prev => ({
                    ...prev,
                    email: e.target.value
                }))
                break
        }
    }

    function goToLinks() {
        history.push(LINKS_PAGE_URL)
    }

    function goToAppearance() {
        history.push(APPEARANCE_PAGE_URL)
    }

    function disconnectSocial(social) {
        setButtonsLoadingState(prev => ({
            ...prev,
            [`${social}Disconnect`]: true
        }))
        shopboardDisconnect(social, () => {
            setButtonsLoadingState(prev => ({
                ...prev,
                [`${social}Disconnect`]: false
            }))
        }, error => {
            setButtonsLoadingState(prev => ({
                ...prev,
                [`${social}Disconnect`]: false
            }))
        })
    }

    function getPlanTitle(name) {
        console.log(name)
        const subscriptedPricingPlanName = Object.keys(PRICING_PLAN_DATA).find(pricingPlanName => PRICING_PLAN_DATA[pricingPlanName].name === name.toLowerCase())
        const subscriptedPricingPlan = PRICING_PLAN_DATA[subscriptedPricingPlanName]

        return subscriptedPricingPlan.presentedName.toUpperCase()
    }

    return (
        <div className={isMobile ? 'mobile-account-page-container' : 'account-page-container'}>
            {
                isMobile ? <ContentDialogTopbar
                    title={t('ACCOUNT_PAGE_TITLE')}
                    onBackButtonClick={() => history.goBack()}
                    rightElement={<></>}
                /> : <div className="account-page-title dashboard-page-title">{t('ACCOUNT_PAGE_TITLE')}</div>
            }
            <div className="account-page-content">
                <AccountSection
                    title={t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_TITLE')}
                    sectionContentClassName='account-page-my-information-section-content'
                >
                    <>
                        {
                            !userDetails?.isAdmin && <ImageBox
                                image={userDetails?.influencerImageUrl}
                                className='account-page-influencer-profile-image-container'
                                imageClassName='account-page-influencer-profile-image'
                                roundImage={true}
                            />
                        }
                        <div className='store-settings-page-shop-website-section-subtitle-input-container'>
                            <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_USERNAME_INPUT_LABEL')}</div>
                            <SettingsInput
                                name={ACCOUNT_PAGE_USERNAME_INPUT_NAME}
                                value={userDetails?.isAdmin ? userDetails?.username : userDetails?.influencerUsername}
                                isDisabled={true}
                                onValueChange={handleInputChange}
                            />
                        </div>
                        <div className='store-settings-page-shop-website-section-subtitle-input-container'>
                            <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_EMAIL_INPUT_LABEL')}</div>
                            <SettingsInput
                                name={ACCOUNT_PAGE_EMAIL_INPUT_NAME}
                                value={inputState.email}
                                isDisabled={true}
                                onValueChange={handleInputChange}
                            />
                        </div>
                        {
                            !userDetails?.isAdmin && <div className='store-settings-page-shop-website-section-subtitle-input-container'>
                                <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_MY_INFORMATION_SECTION_MERCHANT_INPUT_LABEL')}</div>
                                <SettingsInput
                                    content={<div className='account-page-merchant'>
                                        {
                                            userDetails?.businessLogo && <ImageBox
                                                image={userDetails?.businessLogo}
                                                className='account-page-merchant-logo'
                                            />
                                        }
                                        <div className="account-page-merchant-username">{userDetails?.username}</div>
                                    </div>}
                                />
                            </div>
                        }
                    </>
                </AccountSection>
                {
                    userDetails?.isAdmin && <>
                        <AccountSection
                            title={t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_TITLE')}
                            sectionContentClassName='account-page-current-account-section-content'
                        >
                            <>
                                <div className="account-page-current-account-section-username">@{userDetails?.username}</div>
                                <div className="account-page-current-account-section-plan-details-container">
                                    <div className="account-page-current-account-section-plan-details">
                                        <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_PLAN_DETAILS_TITLE')}</div>
                                        <div className="account-page-current-account-section-plan-details-plan-name">{getPlanTitle(userDetails?.subscriptionPlan)}</div>
                                    </div>
                                    {!isMobile && <div className="account-page-current-account-section-upgrade-button" onClick={upgradePlan}>{t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_UPGRADE_BUTTON_TEXT')}</div>}
                                </div>
                                {isMobile && <div className="mobile-account-page-current-account-section-upgrade-button" onClick={upgradePlan}>{t('ACCOUNT_PAGE_CURRENT_ACCOUNT_SECTION_UPGRADE_BUTTON_TEXT')}</div>}
                            </>
                        </AccountSection>
                        <AccountSection
                            title={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_TITLE')}
                            sectionContentClassName='account-page-store-profile-section-content'
                        >
                            <>
                                {
                                    hasShopifyStore() ? <>
                                        <div className="account-page-store-profile-section-content-row">
                                            <div className="account-page-store-profile-section-logo-name-container">
                                                <ImageBox
                                                    image={SHOPIFY_LOGO_URL}
                                                    className='account-page-store-profile-section-logo-container'
                                                />
                                                <div className="account-page-store-profile-section-store-name">{userDetails?.businessName}</div>
                                            </div>
                                            <div className="account-page-store-profile-section-buttons-container">
                                                <div className="account-page-store-profile-section-links-button account-page-store-profile-section-button" onClick={goToLinks}>{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_LINKS_BUTTON_TEXT')}</div>
                                                <div className="account-page-store-profile-section-links-button account-page-store-profile-section-button" onClick={goToAppearance}>{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_APPEARANCE_BUTTON_TEXT')}</div>
                                            </div>
                                        </div>
                                    </> : <ShopifyConnectStore
                                        isConnectingStore={isConnectingStore}
                                        isShopifyConnectionFail={isShopifyConnectionFail}
                                        connectShopifyStoreFunction={connectShopifyStore} />
                                }
                                {
                                    hasShopifyStore() && <>
                                        <div className="account-page-store-profile-section-spacer"></div>
                                        <div className='account-page-store-profile-section-buttons-section' style={isMobile ? { flexDirection: 'column-reverse' } : { flexDirection: 'row' }}>
                                            <LoaderButton
                                                buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_FORCE_PRODUCT_SYNC_BUTTON_TEXT')}
                                                className='account-page-store-profile-section-force-product-sync-button'
                                                isLoading={buttonsLoadingState.forceProductSync}
                                                onClick={syncProducts}
                                                loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                            />
                                            <div className="account-page-store-profile-section-force-product-sync-instruction">{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_FORCE_PRODUCT_SYNC_INSTRUCTION')}</div>
                                        </div>
                                    </>
                                }
                                {/* {
                                    hasShopifyStore() && <>
                                        <div className="account-page-store-profile-section-spacer"></div>
                                        <div className='account-page-store-profile-section-buttons-section' style={isMobile ? { flexDirection: 'column-reverse' } : { flexDirection: 'row' }}>
                                            <LoaderButton
                                                buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECT_BUTTON_TEXT')}
                                                className='account-page-store-profile-section-disconnect-button'
                                                onClick={openDisconnectConfirmDialog}
                                                loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                            />
                                            <div className="account-page-store-profile-section-disconnection-instruction">{t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECTION_INSTRUCTION')}</div>
                                        </div>
                                    </>
                                } */}
                            </>
                        </AccountSection>
                        <AccountSection
                            title={t('ACCOUNT_PAGE_PASSWORD_SECTION_TITLE')}
                            sectionContentClassName='account-page-account-actions-section-content'
                        >
                            <>
                                <div className="account-page-account-actions-section-container">
                                    <div className="settings-pages-subsection-title">{t('ACCOUNT_PAGE_ACCOUNT_ACTIONS_SECTION_RESET_PASSWORD_SUBTITLE')}</div>
                                    <LoaderButton
                                        buttonText={t('ACCOUNT_PAGE_ACCOUNT_ACTIONS_SECTION_RESET_PASSWORD_BUTTON_TEXT')}
                                        isLoading={buttonsLoadingState.resetPassword}
                                        className={`${isMobile ? 'mobile-account-page-account-actions-section-reset-password-button' : 'account-page-account-actions-section-reset-password-button'} account-page-store-profile-section-button`}
                                        onClick={resetPassword}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                </div>
                            </>
                        </AccountSection>
                        <AccountSection
                            title={t('ACCOUNT_PAGE_SOCIAL_SECTION_TITLE')}
                            sectionContentClassName='account-page-social-section-content'
                        >
                            <div className="account-page-social-section-social-status-container">
                                <ShopboardSocialStatus
                                    title={t('SHOPBOARD_SOCIALS_INSTAGRAM_TITLE')}
                                    showStyledButton={false}
                                    showImage={true}
                                    image={<ImageBox
                                        image={INSTAGRAM_LOGO_URL}
                                        className='account-page-social-section-status-image'
                                    />}
                                    postsCount={!isShopboardConnected(SHOPBOARD_SOCIALS.INSTAGRAM) ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.numberOfPosts}
                                    social={SHOPBOARD_SOCIALS.INSTAGRAM}
                                    username={!isShopboardConnected(SHOPBOARD_SOCIALS.INSTAGRAM) ? "" : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.INSTAGRAM]?.username}
                                    titleStyles={{fontWeight: '500'}}
                                />
                                {
                                    isShopboardConnected(SHOPBOARD_SOCIALS.INSTAGRAM) && <LoaderButton
                                        buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECT_BUTTON_TEXT')}
                                        isLoading={buttonsLoadingState.instagramDisconnect}
                                        className={'account-page-social-section-social-status-disconnect-button'}
                                        onClick={() => disconnectSocial(SHOPBOARD_SOCIALS.INSTAGRAM)}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                }
                            </div>
                            <div className="account-page-social-section-social-status-container">
                                <ShopboardSocialStatus
                                    title={t('SHOPBOARD_SOCIALS_TIKTOK_TITLE')}
                                    showStyledButton={false}
                                    showImage={true}
                                    image={<ImageBox
                                        image={TIKTOK_LOGO_URL}
                                        className='account-page-social-section-status-image'
                                    />}
                                    postsCount={!isShopboardConnected(SHOPBOARD_SOCIALS.TIKTOK) ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.numberOfPosts}
                                    social={SHOPBOARD_SOCIALS.TIKTOK}
                                    username={!isShopboardConnected(SHOPBOARD_SOCIALS.TIKTOK) ? "" : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.TIKTOK]?.username}
                                    titleStyles={{fontWeight: '500'}}
                                />
                                {
                                    isShopboardConnected(SHOPBOARD_SOCIALS.TIKTOK) && <LoaderButton
                                        buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECT_BUTTON_TEXT')}
                                        isLoading={buttonsLoadingState.tiktokDisconnect}
                                        className={'account-page-social-section-social-status-disconnect-button'}
                                        onClick={() => disconnectSocial(SHOPBOARD_SOCIALS.TIKTOK)}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                }
                            </div>
                            <div className="account-page-social-section-social-status-container">
                                <ShopboardSocialStatus
                                    title={t('SHOPBOARD_SOCIALS_PINTEREST_TITLE')}
                                    showStyledButton={false}
                                    showImage={true}
                                    image={<ImageBox
                                        image={PINTEREST_LOGO_URL}
                                        className='account-page-social-section-status-image'
                                    />}
                                    postsCount={!isShopboardConnected(SHOPBOARD_SOCIALS.PINTEREST) ? null : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.numberOfPosts}
                                    social={SHOPBOARD_SOCIALS.PINTEREST}
                                    username={!isShopboardConnected(SHOPBOARD_SOCIALS.PINTEREST) ? "" : userDetails?.shopboard?.socialMedias[SHOPBOARD_SOCIALS.PINTEREST]?.username}
                                    titleStyles={{fontWeight: '500'}}
                                />
                                {
                                    isShopboardConnected(SHOPBOARD_SOCIALS.PINTEREST) && <LoaderButton
                                        buttonText={t('ACCOUNT_PAGE_STORE_PROFILE_SECTION_DISCONNECT_BUTTON_TEXT')}
                                        isLoading={buttonsLoadingState.pinterestDisconnect}
                                        className={'account-page-social-section-social-status-disconnect-button'}
                                        onClick={() => disconnectSocial(SHOPBOARD_SOCIALS.PINTEREST)}
                                        loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                    />
                                }
                            </div>
                        </AccountSection>
                        <AccountSection
                            sectionContentClassName='account-page-danger-zone-section-content'
                        >
                            <div className='account-page-danger-zone-section-container' style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
                                <LoaderButton
                                    buttonText={t('ACCOUNT_PAGE_DANGER_ZONE_SECTION_DELETE_ACCOUNT_BUTTON_TEXT')}
                                    isLoading={buttonsLoadingState.deleteAccount}
                                    className={isMobile ? 'mobile-account-page-danger-zone-section-delete-account-button' : 'account-page-danger-zone-section-delete-account-button'}
                                    onClick={onDeleteAccountButtonClick}
                                    loaderCircleColor={SETTINGS_PAGES_BUTTON_LOADER_CIRCLE_COLOR}
                                />
                                <div className="account-page-danger-zone-section-instruction">{t('ACCOUNT_PAGE_DANGER_ZONE_SECTION_DELETE_ACCOUNT_INSTRUCTION')}</div>
                            </div>
                        </AccountSection>
                    </>
                }
            </div>
            <ConfirmDisconnectDialog
                isDialogOpen={isConfirmDisconnectDialogOpen}
                handleDialogClose={closeConfirmDisconnectDialog}
                isDisconnecting={buttonsLoadingState.disconnect}
                onDisconnectClick={disconnect}
            />
            <PaydinDialog
                title={dialogState.title}
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                leftButtonType={PAYDIN_DIALOG_BUTTON_TYPES.PRIMARY}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                onRightButtonClick={dialogState.rightButtonClickHandler}
                isLeftButtonWithLoader={false}
                dialogLayout={dialogState.dialogLayout}
            />
            <ApprovalMessage
                isMessageShown={approvalMessageState.isMessageShown}
                closeMessage={hideApprovalMassage}
                text={approvalMessageState.text}
            />
        </div >
    )
}
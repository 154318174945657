export const ADMIN_PAGES_URL_PREFIX = '/admin'
export const LINKS_URL_PREFIX = '/links'
export const MULTILINKS_URL_PREFIX = '/multilinks'
export const SHORTS_URL_PREFIX = '/shorts'
export const SPECIFIC_SHORT_URL_PREFIX = SHORTS_URL_PREFIX + '/:shortId'
export const SPECIFIC_LINK_URL_PREFIX = LINKS_URL_PREFIX + '/:linkId'
export const PREVIEW_LINK_PAGE_SUFFIX = '/preview'
export const PREVIEW_INFLUECNER_PAGE_SUFFIX = '/preview'
export const EDIT_URL_SUFFIX = '/edit'
export const INFLUENCERS_PAGE_URL_PREFIX = '/influencers'
export const SPECIFIC_INFLUENCER_URL_PREFIX = '/:influencerUsername'
export const CREATE_INFLUENCER_URL_SUFFIX = '/new'
export const INFLUENCERS_URL_PREFIX = ADMIN_PAGES_URL_PREFIX + INFLUENCERS_PAGE_URL_PREFIX

export const PAYLINK_SITE_URL = 'https://www.paylinkshop.com'

export const HOME_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/home'
export const SHOPBOARD_AUTH_PAGE_URL = '/:social/auth'
export const SHOPBOARD_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/shopboard'
export const MAGIC_STORE_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/magic-store'
export const GOTO_HOMEPAGE_URLS = ['/', ADMIN_PAGES_URL_PREFIX]

export const LOGIN_PAGE_URL = '/login'
export const LOGIN_STORE_PAGE_URL = '/login-store'
export const LOGIN_INFLUENCER_PAGE_URL = '/login/influencer'

export const SIGN_UP_PAGE_URL = '/register'
export const REGISTER_STORE_PAGE_URL = '/register-store'
export const SIGN_UP_REQUEST_PAGE_URL = '/register-request'

export const SHORTS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SHORTS_URL_PREFIX

export const LINKS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + LINKS_URL_PREFIX
export const PREVIEW_LINK_PAGE_URL = ADMIN_PAGES_URL_PREFIX + SPECIFIC_LINK_URL_PREFIX + PREVIEW_LINK_PAGE_SUFFIX

export const INFLUENCERS_PAGE_URL = INFLUENCERS_URL_PREFIX

export const MULTILINKS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + MULTILINKS_URL_PREFIX

export const TRENDS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/trends'

export const ANALYTICS_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/analytics'

export const ACCOUNT_PAGE_URL = ADMIN_PAGES_URL_PREFIX + '/my-account'

export const FORGOT_PASSWORD_PAGE_URL = '/forgot-password'

export const INFLUENCER_FORGOT_PASSWORD_PAGE_URL = '/forgot-password/influencer'

export const CREATE_NEW_PASSWORD_PAGE_URL = '/reset-password'

export const EXTERNAL_AUTH_PAGE_URL = '/auth'

export const PRIVACY_POLICY_PAGE_URL = '/privacy-policy'
export const PRIVACY_POLICY_PAYLINK_SITE_URL = PAYLINK_SITE_URL + '/privacy'

export const TERMS_PAGE_URL = '/terms'
export const TERMS_PAYLINK_SITE_URL = PAYLINK_SITE_URL + '/terms'

export const SETTINGS_PAGE_URL = `${ADMIN_PAGES_URL_PREFIX}/settings`

export const APPEARANCE_PAGE_URL = `${ADMIN_PAGES_URL_PREFIX}/appearance`

export const PRICING_PLANS_PAGE_URL = '/pricing-plans'

export const SHOPIFY_HELP_PAGE_URL = '/help/shopify'

export const ACCOUNT_VERIFICATION_PAGE_URL = '/verify-account'

export const ROLE_QUERY_PARAMETER_KEY = 'role'
export const ROLE_QUERY_PARAMETER_EDIT_VALUE = 'edit'
export const ROLE_QUERY_PARAMETER_CREATE_VALUE = 'create'
export const LINK_ID_QUERY_PARAMETER_KEY = 'linkId'
export const AUTH_QUERY_PARAMETER_KEY = 'auth'
export const RESET_PASSWORD_TOKEN_QUERY_PARAMETER_KEY = 'token'
export const PRODUCT_STATUS_QUERY_PARAMETER = 'product_status'
export const IS_OUT_OF_STOCK_QUERY_PARAMETER = 'include_out_of_stock'
export const IS_SEARCH_IN_TAGS_QUERY_PARAMETER = 'include_search_tags'
export const LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER = 'merchant'
export const LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER = 'influencer'
export const LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER = 'password'
export const SELECTED_INFLUENCER_USERNAME_QUERY_PARAMETER = 'selected_influencer_username'
export const SHOPBOARD_AUTH_PAGE_CODE_QUERY_PARAMETER = 'code'
import { CONTENT_TYPES, LINK_PRODUCT_SELECT_MAX_LIMIT, VIEW_MODES } from 'constants/general.constants'
import { useUserDetailsContext } from 'contexts/User'
import AddProductsDialog from 'dialogs/AddProductsDialog/AddProductsDialog'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import React, { useEffect, useState } from 'react'

export default function AddLInkFlow({
    trigger,
    setTrigger = () => { },
    onAddLinkFlowDone = () => { },
    data = {}
}) {
    const {
        endEditing
    } = useUserDetailsContext()
    const [isLinkContentDialogOpen, setIsLinkContentDialogOpen] = useState(false)
    const [isProductsDialogOpen, setIsProductsDialogOpen] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])

    useEffect(() => {
        if (trigger) {
            setIsProductsDialogOpen(true)
            setTrigger(false)
        }
    }, [trigger])

    function closeProductsDialog() {
        endEditing()
        setIsProductsDialogOpen(false)
    }

    function onProductsSelected() {
        setTimeout(() => {
            setIsLinkContentDialogOpen(true)
        }, 200)
    }

    function closeLinkContentDialog() {
        resetData()
        endEditing()
        setIsLinkContentDialogOpen(false)
    }

    function resetData() {
        setSelectedProducts([])
    }

    function onAddLink(newLink) {
        resetData()
        onAddLinkFlowDone(newLink)
    }

    return (
        <>
            <AddProductsDialog
                isDialogOpen={isProductsDialogOpen}
                handleDialogClose={closeProductsDialog}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                onFinishProductsSelection={onProductsSelected}
                onCancel={resetData}
                leftButtonText='Cancel'
                rightButtonText='Next'
                hasContentTopbar={true}
                selectMinLimit={1}
                selectMaxLimit={LINK_PRODUCT_SELECT_MAX_LIMIT}
            />
            <ContentDialog
                isDialogOpen={isLinkContentDialogOpen}
                handleDialogClose={closeLinkContentDialog}
                mode={VIEW_MODES.CREATE}
                data={{
                    linkData: {
                        products: selectedProducts
                    },
                    influencerUsername: data?.influencerUsername
                }}
                contentType={CONTENT_TYPES.LINK}
                onCreateSuccess={onAddLink}
            />
        </>
    )
}
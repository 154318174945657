import { environment } from "conf"
import { ANALYTICS_API_SUFFIX, AUTH_API_ROUTE, CONNECT_API_SUFFIX, DISCONNECT_API_SUFFIX, POSTS_API_SUFFIX, SHOPBOARD_API_BASE_URL, TAG_PRODUCTS_API_SUFFIX, VISIBLE_API_SUFFIX } from "constants/api.constants"
import { request_auth } from "services/RequestWrapper"

const baseUrl = environment.base_url + SHOPBOARD_API_BASE_URL

/*
    Retrieves the shopboard status for the current user in all social medias.

    Method: [GET]
    URL: /admin/v1/shopboard

*/
export function getShopboardStatus() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves the shopboard analytics for the current user in all social medias.

    Method: [GET]
    URL: /admin/v1/shopboard/analytics

    Query params:
    =============
    (*) fromDate - The start date of the shopboard analytics' date range
    (*) toDate - The end date of the shopboard analytics' date range, inclusive

*/
export function getShopboardAnalytics(fromDate = null, toDate = null) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}${ANALYTICS_API_SUFFIX}${fromDate !== null && toDate !== null ? `?from_date=${fromDate}&to_date=${toDate}` : ''}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Toggles the shopboard post visibility in a specific post of a social media.

    Method: [POST]
    URL: /admin/v1/shopboard/<social>/<postId>/visible

    Route params:
    =============
    (*) social - The name of the social media containing the post
    (*) postId - The post id

*/
export function toggleShopboardPostVisibility(social, postId, isVisible) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                is_visible: isVisible
            }
        }

        const url = `${baseUrl}/${social}/${postId}${VISIBLE_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Tags products for a specific post of a social media.

    Method: [POST]
    URL: /admin/v1/shopboard/<social>/<postId>/tag_products

    Route params:
    =============
    (*) social - The name of the social media containing the post
    (*) postId - The post id

    Body params:
    ============
    (*) productIds - An array of products' ids to tag

*/
export function tagShopboardPostProducts(social, postId, productIds) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                product_ids: productIds
            }
        }

        const url = `${baseUrl}/${social}/${postId}${TAG_PRODUCTS_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves the list of posts in a specific social media (paginated).

    Method: [POST]
    URL: /admin/v1/shopboard/<social>/posts

    Route params:
    =============
    (*) social - The name of the social media containing the post

    Body params:
    ============
    (*) count - The number of items per page
    (*) skip - The number of items to skip

*/
export function getShopboardPosts(social, count, skip) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }

        const url = `${baseUrl}/${social}${POSTS_API_SUFFIX}?count=${count}&skip=${skip}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Connects the current user to the requested social media.

    Method: [GET]
    URL: /admin/v1/shopboard/<social>/connect

    Route params:
    ==============
    (*) social - The social media name (lowercased) to which the user requests to connect
*/
export function connectShopboardSocialMedia(social) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}/${social}${CONNECT_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Disconnects the current user from the requested social media.

    Method: [POST]
    URL: /admin/v1/shopboard/<social>/auth/disconnect

    Route params:
    ==============
    (*) social - The social media name (lowercased) to which the user requests to connect
*/
export function disconnectShopboardSocialMedia(social) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST'
        }

        const url = `${baseUrl}/${social}${AUTH_API_ROUTE}${DISCONNECT_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Connects the current user to the requested social media.

    Method: [POST]
    URL: /admin/v1/shopboard/<social>/auth/connect

    Route params:
    ==============
    (*) social - The social media name (lowercased) to which the user requests to connect

    Query params:
    =============
    (*) code - The connection code for the authentication
*/
export function authenticateShopboardSocialMedia(social, code) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                code
            }
        }

        const url = `${baseUrl}/${social}${AUTH_API_ROUTE}${CONNECT_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}
import { environment } from "conf";
import { 
    INFLUENCERS_ACTIVATE_INFLUENCER_API_SUFFIX, 
    INFLUENCERS_API_BASE_URL, 
    INFLUENCERS_CREATE_INFLUENCER_API_SUFFIX, 
    INFLUENCERS_EDIT_INFLUENCER_API_SUFFIX, 
    ANALYTICS_API_SUFFIX, 
    INFLUENCERS_LOGIN_API_LOGIN_ROUTE, 
    PASSWORD_RESET_REQUEST_API_ROUTE, 
    INFLUENCERS_VALIDATE_INFLUENCER_USERNAME_API_SUFFIX, 
    INFLUENCERS_PASSWORD_RESET_API_ROUTE
} from "constants/api.constants";
import { request_auth } from "services/RequestWrapper";


const baseUrl = environment.base_url + INFLUENCERS_API_BASE_URL

/*
    Retrieves all the influencers of the current logged in user.

    Method: [GET]
    URL: /admin/v1/influencers
*/
export function GetInfluencers() {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = baseUrl
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves a specific influencer.

    Method: [GET]
    URL: /admin/v1/influencers/<influencerUsername>

    Route params:
    =============
        (*) influencerUsername - The username of the requested influencer
*/
export function GetInfluencer(influencerUsername) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}/${influencerUsername}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves analytics data for a specific influencer.

    Method: [GET]
    URL: /admin/v1/influencers/<influencerUsername>/analytics

    Route params:
    =============
        (*) influencerUsername - The username of the influencer
    
    Query params:
    ==============
        (*) fromDate - The start of the time range of the analytics data
        (*) toDate - The end of the time range of the analytics data
*/
export function GetInfluencerAnalytics(influencerUsername, fromDate, toDate) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}/${influencerUsername}${ANALYTICS_API_SUFFIX}${fromDate !== null && toDate !== null ? `?from_date=${fromDate}&to_date=${toDate}` : ''}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Retrieves analytics data for all influencers.

    Method: [GET]
    URL: /admin/v1/influencers/analytics

    Query params:
    ==============
        (*) fromDate - The start of the time range of the analytics data, defaults to null
        (*) toDate - The end of the time range of the analytics data, defaults to null
*/
export function GetInfluencersAnalytics(fromDate = null, toDate = null) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }

        const url = `${baseUrl}${ANALYTICS_API_SUFFIX}${fromDate !== null && toDate !== null ? `?from_date=${fromDate}&to_date=${toDate}` : ''}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Deactivates an influencer.

    Method: [POST]
    URL: /admin/v1/influencers/<influencerUsername>/activate

    Route params:
    =============
        (*) influencerUsername - The username of the influencer
*/
export function deactivateInfluencer(influencerUsername) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                activate: false
            }
        }

        const url = `${baseUrl}/${influencerUsername}${INFLUENCERS_ACTIVATE_INFLUENCER_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Activates an influencer.

    Method: [POST]
    URL: /admin/v1/influencers/<influencerUsername>/activate

    Route params:
    =============
        (*) influencerUsername - The username of the influencer
*/
export function activateInfluencer(influencerUsername) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                activate: true
            }
        }

        const url = `${baseUrl}/${influencerUsername}${INFLUENCERS_ACTIVATE_INFLUENCER_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Creates an influencer.

    Method: [POST]
    URL: /admin/v1/influencers/create

    Body params:
    =============
        (*) influencerUsername - The username of the influencer
        (*) email - The email of the influencer
        (*) profileImageUrl - The profile image url of the influencer
        (*) promocode - The promocode of the influencer
        (*) commission - The commission of the influencer
        (*) influencerPermissions - The analytics permissions of the influencer
*/
export function createInfluencer(influencerUsername, email, profileImageUrl, promocode, commission, phoneNumber, influencerPermissions) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username: influencerUsername,
                email,
                profile_image_url: profileImageUrl,
                promocode,
                commission,
                phone: phoneNumber,
                permissions: {
                    analytics_clicks_enabled: influencerPermissions?.clicksEnabled,
                    analytics_sales_enabled: influencerPermissions?.salesEnabled,
                    analytics_revenue_enabled: influencerPermissions?.revenueEnabled,
                    analytics_include_vat: influencerPermissions?.includeVat,
                    allow_creating_and_editing_links: influencerPermissions?.isActiveInfluencer
                }
            }
        }

        const url = `${baseUrl}${INFLUENCERS_CREATE_INFLUENCER_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Edits an influencer.

    Method: [POST]
    URL: /admin/v1/influencers/<influencerUsername>/edit

    Route params:
    =============
        (*) influencerUsername - The username of the influencer

    Body params:
    =============
        (*) email - The email of the influencer
        (*) profileImageUrl - The profile image url of the influencer
        (*) promocode - The promocode of the influencer
        (*) commission - The commission of the influencer
        (*) influencerPermissions - The analytics permissions of the influencer
*/
export function updateInfluencer(influencerUsername, email, profileImageUrl, promocode, commission, phoneNumber, influencerPermissions) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                email,
                profile_image_url: profileImageUrl,
                promocode,
                commission,
                phone: phoneNumber,
                permissions: {
                    analytics_clicks_enabled: influencerPermissions?.clicksEnabled,
                    analytics_sales_enabled: influencerPermissions?.salesEnabled,
                    analytics_revenue_enabled: influencerPermissions?.revenueEnabled,
                    analytics_include_vat: influencerPermissions?.includeVat,
                    allow_creating_and_editing_links: influencerPermissions?.isActiveInfluencer
                }
            }
        }

        const url = `${baseUrl}/${influencerUsername}${INFLUENCERS_EDIT_INFLUENCER_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Validates the given influencer's username.

    Method: [POST]
    URL: /admin/v1/influencers/validate/username

    Body params:
    =============
        (*) username - The influencer's username for validation
*/
export function validateInfluencerUsername(username) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username
            }
        }

        const url = `${baseUrl}${INFLUENCERS_VALIDATE_INFLUENCER_USERNAME_API_SUFFIX}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Sends a request for resetting the influencer's password.

    Method: [POST]
    URL: /admin/v1/influencers/password/reset/request

    Body params:
    =============
        (*) merchantUsername - The mechant's username
        (*) influencerUsername - The mechant's influencer's username
*/
export function resetInfluencerPasswordRequest(merchantUsername, influencerUsername) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username: merchantUsername,
                influencer_username: influencerUsername
            }
        }

        const url = `${baseUrl}${PASSWORD_RESET_REQUEST_API_ROUTE}`        
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}


/*
    Resets the influencer's password and sends it the info to the mail.

    Method: [POST]
    URL: /admin/v1/influencers/password/reset/request

    Body params:
    =============
        (*) influencerUsername - The mechant's influencer's username
*/
export function resetInfluencerPassword(influencerUsername) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET',
        }

        const url = `${baseUrl}/${influencerUsername}${INFLUENCERS_PASSWORD_RESET_API_ROUTE}`        
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}

/*
    Logs in as an influencer.

    Method: [POST]
    URL: /admin/v1/influencers/login

    Body params:
    =============
        (*) email - The influencer's email for validation      
*/
export function influencerLoginAPI(merchantUsername, influencerUsername, influencerPassword) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                admin_username: merchantUsername,
                username: influencerUsername,
                password: influencerPassword
            }
        }

        const url = `${baseUrl}${INFLUENCERS_LOGIN_API_LOGIN_ROUTE}`
        request_auth(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(reject)
    })
}
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './MultiSelectDialog.css'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useHistoryContext } from 'contexts/History'
import { ADD_PRODUCTS_DIALOG_PRODUCTS_IN_COLUMNS, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS, LINK_PAGES_MAX_WIDTH, MAX_ITEMS_SEARCH_RESULT_NUMBER, MULTI_SELECT_DIALOG_DESKTOP_HEIGHT_PX, MULTI_SELECT_DIALOG_DONE_BUTTON_STRIP_HEIGHT_PX, MULTI_SELECT_DIALOG_SELECTION_LIMIT_REACHED_ALERT_HIDE_DELAY_MILLISECONDS } from 'constants/general.constants'
import PaydinCheckbox from 'components/PaydinCheckbox/PaydinCheckbox'
import { CiSearch } from 'react-icons/ci'
import { IoCloseOutline } from 'react-icons/io5'
import Loader from 'components/Loader/Loader'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import LoaderButton from 'components/LoaderButton/LoaderButton'
import { AiOutlineClose } from 'react-icons/ai'
import useOnScreen from 'hooks/useOnScreen'

/**
 * Represents a dialog for multi-selecting elements.
 * @param {boolean} isDialogOpen - Determins whether this dialog is shown or not
 * @param {function} handleDialogClose - A function to perform whenever this dialog is closed
 * @param {array} selectedItems - The list of selected items
 * @param {function} setSelectedItems - A function for setting the list of selected items
 * @param {string} title - The title of this dialog
 * @param {string} itemName - The name of the displayed item type
 * @param {component} viewItemComponent - The component of the rendered item
 * @param {number} selectMaxLimit - The upper bound of items the user can select from the list. If equals to 0, then no limit applied
 * @param {number} selectMinLimit - The lower bound of items the user can select from the list. If equals to 0, then no limit applied
 * @param {component} filterComponent - The component of the rendered filters for this dialog's items list. If equals non provided, then no filters are included
 * @param {number} columnsCount - The number of columns to display
 * @param {function} dataGenerator - This function generates the data to display. 
 *      It accepts 3 parameters: q - The search query text, count - The max number of the elements retrieved per request, skip - The starting point of the elements retrieval.
 * @param {function} dataExtractor - This function extracts and parses data for a single raw element
 * @param {boolean} filtersChanged - A boolean flag that triggers a filter change
 */
export default function MultiSelectDialog({
    isDialogOpen = false,
    handleDialogClose = () => { },
    selectedItems = [],
    setSelectedItems = () => { },
    title = '',
    leftButtonText = 'Cancel',
    rightButtonText = 'Done',
    itemName = '',
    viewItemComponent: ViewItemComponent,
    selectMaxLimit = 0,
    selectMinLimit = 0,
    filterComponent: FilterComponent = null,
    columnsCount = 7,
    dataGenerator = (q, count, skip) => { },
    dataExtractor = (rawItem) => { },
    filtersChanged = false,
    searchResultFilter = items => items,
    onFinishProductsSelection = () => { },
    onCancel = () => { },
    hasContentTopbar = true,
    resetSelectedOnFinish = false
}) {
    const { t } = useTranslation()
    const history = useHistoryContext()

    const [tempSelectedItems, setTempSelectedItems] = useState(selectedItems)
    const [itemsResult, setItemsResult] = useState([])
    const [cachedItemsResult, setCachedItemsResult] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [hasSearchFailed, setHasSearchFailed] = useState(false)
    const [hasMoreItems, setHasMoreItems] = useState(true)
    const [allItemsSelected, setAllItemsSelected] = useState(false)
    const [isSearchingItems, setIsSearchingItems] = useState(false)
    const [isSearchingMoreItems, setIsSearchingMoreItems] = useState(false)
    const [returnedRequestParams, setReturnedRequestParams] = useState({
        id: 0,
        skip: 0,
        items: []
    })
    const [currentRequestId, setCurrentRequestId] = useState(0)
    const [shouldShowNoItems, setShouldShowNoItems] = useState(false)
    const [isLimitReachedAlertShown, setIsLimitReachedAlertShown] = useState(false)

    const searchBoxInputRef = useRef(null)
    const itemsListRef = useRef(null)
    const productsDetailsRef = useRef(null)
    const productsTopBarRef = useRef(null)
    const filtersRef = useRef(null)
    const productsStatusBarRef = useRef(null)
    const typingTimeoutRef = useRef(null)
    const isSelectionChangedRef = useRef(false)

    const paginationTriggeringElementRef = useRef(null)
    const isOnScreen = useOnScreen(paginationTriggeringElementRef)

    const itemsHeight = useMemo(() => calculateItemsContainerHeight(), [itemsResult])
    const itemsListColumnWidth = `calc((${isMobile ? '100vw' : `${LINK_PAGES_MAX_WIDTH}px`} - 20px - ${isMobile ? 0 : 15}px - ${10 * (columnsCount - 1)}px) / ${columnsCount})`
    useEffect(() => {
        setTempSelectedItems(selectedItems)
    }, [selectedItems])

    useEffect(() => {
        if (filtersChanged) {
            setHasSearchFailed(false)
            setItemsResult([])
            setHasMoreItems(true)
            setShouldShowNoItems(false)
            setIsSearchingItems(true)
            itemSearch(searchQuery, 0, () => {
                resetItemsListScroll(30)
            })
        }
    }, [filtersChanged])

    useEffect(() => {
        setTempSelectedItems(selectedItems)
        setItemsResult(calculateItemsArrayUnion(selectedItems, cachedItemsResult))
    }, [selectedItems, cachedItemsResult])

    useEffect(() => {
        if (currentRequestId !== 0 && currentRequestId === returnedRequestParams.id) {
            if (returnedRequestParams.skip > 0) {
                setItemsResult(prev => ([
                    ...prev,
                    ...returnedRequestParams.items
                ]))
            } else {
                if (cachedItemsResult.length === 0) {
                    setCachedItemsResult(returnedRequestParams.items)
                } else {
                    setItemsResult(returnedRequestParams.items)
                }
            }
            setHasMoreItems(returnedRequestParams.items.length === MAX_ITEMS_SEARCH_RESULT_NUMBER)
            setShouldShowNoItems(returnedRequestParams.items.length === 0)
        }
        if (currentRequestId === returnedRequestParams.id) {
            setIsSearchingItems(false)
            setIsSearchingMoreItems(false)
        }
    }, [returnedRequestParams])

    useEffect(() => {
        if (isDialogOpen) {
            setHasSearchFailed(false)
            setItemsResult([])
            setHasMoreItems(true)
            clearTypingTimeout(typingTimeoutRef.current)
            setShouldShowNoItems(false)
            if (searchQuery) {
                setIsSearchingItems(true)
                typingTimeoutRef.current = setTimeout(() => {
                    itemSearch(searchQuery, 0, () => {
                        resetItemsListScroll(30)
                    })
                }, EDIT_LINK_PAGE_PRODUCT_SEARCH_REQUEST_SEND_DELAY_MILLISECONDS)
            } else {
                if (cachedItemsResult.length === 0) {
                    setIsSearchingItems(true)
                    itemSearch('', 0, items => {
                        setHasMoreItems(items.length === MAX_ITEMS_SEARCH_RESULT_NUMBER)
                        resetItemsListScroll(30)
                    })
                } else {
                    setShouldShowNoItems(true)
                    resetItemsListScroll()
                    setItemsResult(calculateItemsArrayUnion(selectedItems, cachedItemsResult))
                    setIsSearchingItems(false)
                    setHasMoreItems(cachedItemsResult.length === MAX_ITEMS_SEARCH_RESULT_NUMBER)
                }
            }
        } else {
            setSearchQuery("")
        }

        return () => {
            clearTypingTimeout(typingTimeoutRef.current)
        }
    }, [isDialogOpen, searchQuery])


    useEffect(() => {
        if (isOnScreen) {
            handleScroll()
        }
    }, [isOnScreen])

    function setTemporaryItems(items) {
        isSelectionChangedRef.current = true
        setTempSelectedItems(items)
    }

    function calculateItemsContainerHeight() {
        const itemsListHeightPx = `${MULTI_SELECT_DIALOG_DESKTOP_HEIGHT_PX - 30 - productsTopBarRef?.current?.clientHeight - (filtersRef?.current ? filtersRef?.current?.clientHeight : 0) - productsStatusBarRef?.current?.clientHeight - (!isMobile ? MULTI_SELECT_DIALOG_DONE_BUTTON_STRIP_HEIGHT_PX : 0)}px`
        if (isMobile) {
            return `calc(100dvh - 30px - ${productsTopBarRef?.current?.clientHeight + (filtersRef?.current ? filtersRef?.current?.clientHeight : 0) + productsStatusBarRef?.current?.clientHeight}px)`
        }

        return itemsListHeightPx
    }

    function clearTypingTimeout(timeout) {
        timeout && clearTimeout(timeout)
    }

    function calculateItemsArrayUnion(array1, array2) {
        const unionArray = []

        for (let i = 0; i < array1.length; i++) {
            const currentProduct = array1[i]
            if (unionArray.filter(item => item?.id === currentProduct?.id).length === 0) {
                unionArray.push(array1[i])
            }
        }

        for (let i = 0; i < array2.length; i++) {
            const currentProduct = array2[i]
            if (unionArray.filter(item => item?.id === currentProduct?.id).length === 0) {
                unionArray.push(array2[i])
            }
        }

        return unionArray
    }

    function clearSearchQuery() {
        setSearchQuery('')
    }

    function handleSearchChange(e) {
        setSearchQuery(e.target.value)
    }

    function closeDialog() {
        if (handleDialogClose)
            handleDialogClose()
        history.goBack()
    }

    function onItemsSelectionFinish() {
        setSelectedItems(tempSelectedItems)
        closeDialog()
        if (resetSelectedOnFinish) {
            setTempSelectedItems([])
        }
        onFinishProductsSelection(tempSelectedItems)
    }

    function onCancelClick() {
        isSelectionChangedRef.current = false
        setTempSelectedItems(selectedItems)
        onCancel()
        closeDialog()
    }

    function handleScroll() {
        searchBoxInputRef?.current?.blur()
        if (!isSearchingMoreItems && hasMoreItems) {
            setIsSearchingMoreItems(true)
            itemSearch(searchQuery, itemsResult.length)
        }
    }

    function resetItemsListScroll(delay = null) {
        if (delay !== null) {
            setTimeout(() => {
                if (itemsListRef?.current) {
                    itemsListRef?.current?.scrollTo(0, 0)
                }
            }, delay)
        } else {
            if (itemsListRef?.current) {
                itemsListRef?.current?.scrollTo(0, 0)
            }
        }
    }

    function selectAllItems() {
        if (allItemsSelected) {
            setTemporaryItems([])
        } else {
            setTemporaryItems(itemsResult)
        }
    }

    function itemSearch(query, skip, onResultsReceived = () => { }) {
        let id = currentRequestId + 1
        setCurrentRequestId(prev => prev + 1)
        dataGenerator(query, MAX_ITEMS_SEARCH_RESULT_NUMBER, skip)
            .then(items => {
                const result = searchResultFilter(items.map(item => dataExtractor(item)))
                setReturnedRequestParams({
                    id,
                    items: result,
                    skip
                })
                onResultsReceived(items)
            }).catch(error => {
                console.log(error)
                setIsSearchingItems(false)
                setHasSearchFailed(true)
            })
    }

    function isItemSelected(item) {
        const selectedItem = tempSelectedItems.find(currentItem => currentItem?.id === item?.id)
        if (selectedItem) return true
        return false
    }

    function onItemClick(item) {
        if (isItemSelected(item)) {
            removeItem(item)
        } else {
            addItem(item)
        }
    }

    function addItem(item) {
        if (hasSelectionMaxLimit() && tempSelectedItems.length + 1 > selectMaxLimit) {
            if (selectMaxLimit === 1) {
                setTemporaryItems([item])
            } else {
                setIsLimitReachedAlertShown(true)
                setTimeout(() => {
                    setIsLimitReachedAlertShown(false)
                }, MULTI_SELECT_DIALOG_SELECTION_LIMIT_REACHED_ALERT_HIDE_DELAY_MILLISECONDS)
            }
        } else {
            setTemporaryItems([...tempSelectedItems, item])
        }
    }

    function removeItem(itemToRemove) {
        setTemporaryItems(tempSelectedItems.filter(item => item.id !== itemToRemove.id))
    }

    function renderItems() {
        return <div className={`${isMobile ? 'mobile-multi-select-dialog-items' : 'multi-select-dialog-items'}`} style={{ height: itemsHeight }}>
            {
                (isSearchingItems || hasSearchFailed) && <div className={isMobile ? 'mobile-multi-select-dialog-loader-cell' : 'multi-select-dialog-loader-cell'} style={{ backgroundColor: itemsResult.length > 0 ? '#ffffffaa' : '#ffffff', height: itemsHeight }}>
                    {hasSearchFailed ? <div className='multi-select-dialog-no-results-found-container'>
                        <div className="multi-select-dialog-no-results-found-content">
                            <div className="multi-select-dialog-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_SEARCH_FAILED_TEXT')}</div>
                        </div>
                    </div> : <Loader styles={{ height: '20px', width: '20px', position: 'absolute', top: '20px', zIndex: 60 }} />}
                </div>
            }
            {
                isLimitReachedAlertShown && <div className='multi-select-dialog-limit-reached-alert' style={{ height: itemsHeight }}>
                    <div className="multi-select-dialog-limit-reached-alert-message">{t('MULTI_SELECT_DIALOG_SELECTION_LIMIT_REACHED_ALERT_MESSAGE', { limit: selectMaxLimit, itemName })}</div>
                </div>
            }
            {
                cachedItemsResult.length === 0 ? <div className='multi-select-dialog-no-results-found-container' style={{ height: itemsHeight }}>
                    <div className="multi-select-dialog-no-results-found-content">
                        <div className="multi-select-dialog-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_SYNCING_PRODUCTS_TEXT')}</div>
                    </div>
                </div> : (
                    itemsResult.length > 0 ? <div ref={itemsListRef} className={`${isMobile ? 'mobile-multi-select-dialog-items-list' : 'multi-select-dialog-items-list'} ${hasContentTopbar ? 'has-topbar' : ''}`} style={{ height: itemsHeight }}>
                        <div className={isMobile ? 'mobile-multi-select-dialog-items-list-content' : 'multi-select-dialog-items-list-content'} style={{ gridTemplateColumns: `repeat(${columnsCount}, ${itemsListColumnWidth})` }}>
                            {
                                itemsResult.map((item, index) => {
                                    if (index === itemsResult.length - 1 - ADD_PRODUCTS_DIALOG_PRODUCTS_IN_COLUMNS) {
                                        return <ViewItemComponent
                                            key={item?.id}
                                            data={item}
                                            isItemSelected={isItemSelected(item)}
                                            onItemClick={() => onItemClick(item)}
                                            elementRef={paginationTriggeringElementRef}
                                        />
                                    }
                                    return <ViewItemComponent
                                        key={item?.id}
                                        data={item}
                                        isItemSelected={isItemSelected(item)}
                                        onItemClick={() => onItemClick(item)}
                                    />
                                })
                            }
                        </div>
                        {
                            hasMoreItems && <div className={isMobile ? 'mobile-multi-select-dialog-pagination-loader-cell' : 'multi-select-dialog-pagination-loader-cell'}>
                                <Loader styles={{ height: '20px', width: '20px', position: 'absolute', inset: 0, margin: 'auto', zIndex: 60 }} />
                            </div>
                        }
                    </div> : (shouldShowNoItems && <div className='multi-select-dialog-no-results-found-container' style={{ height: itemsHeight }}>
                        <div className="multi-select-dialog-no-results-found-content">
                            <div className="multi-select-dialog-no-results-found-text">{t('ADD_PRODUCTS_DIALOG_NO_RESULTS_FOUND_TEXT')}</div>
                        </div>
                    </div>)
                )
            }
        </div>
    }

    function hasSelectionMaxLimit() {
        return selectMaxLimit > 0
    }

    function hasSelectionMinLimit() {
        return selectMinLimit > 0
    }

    function isDoneButtonDisabled() {
        return hasSelectionMinLimit() && tempSelectedItems?.length < selectMinLimit
    }

    return (
        <>
            {(!isMobile && isDialogOpen) && <AiOutlineClose className="content-dialog-close-button" onClick={() => history.goBack()} />}
            <PaydinDialog
                isDialogOpen={isDialogOpen}
                handleDialogClose={handleDialogClose}
                hasNoWidthLimit={true}
                backdropColor='rgba(0, 0, 0, 0.75)'
                borderRadiusPx={isMobile ? 0 : 5}
                paddingPx={0}
                width={LINK_PAGES_MAX_WIDTH}
                minHeight={isMobile ? '100dvh' : '84dvh'}
                height={isMobile ? '100dvh' : '84dvh'}
                margin='0px'
                dialogLayout={<>
                    <div className={isMobile ? "mobile-multi-select-dialog-content" : "multi-select-dialog-content"}>
                        <ContentDialogTopbar
                            title={title}
                            leftElement={<>
                                <LoaderButton
                                    className='page-view-topbar-cancel-button'
                                    buttonText={leftButtonText}
                                    isDisabled={isDoneButtonDisabled()}
                                    onClick={onCancelClick}
                                />
                            </>}
                            rightElement={<>
                                <LoaderButton
                                    className='page-view-topbar-save-button'
                                    buttonText={rightButtonText}
                                    isDisabled={isDoneButtonDisabled()}
                                    onClick={onItemsSelectionFinish}
                                />
                            </>}
                        />
                        <div ref={productsDetailsRef} className={`${isMobile ? "mobile-multi-select-dialog-details" : "multi-select-dialog-details"} ${hasContentTopbar ? 'has-topbar' : ''}`}>
                            <div className={isMobile ? "mobile-multi-select-dialog-filter-container" : "multi-select-dialog-filter-container"}>
                                {FilterComponent && <FilterComponent filterRef={filtersRef} />}
                                <div className="multi-select-dialog-topbar-search-box" style={{ width: isMobile ? '100%' : '300px' }}>
                                    <input ref={searchBoxInputRef} className='multi-select-dialog-topbar-search-box-input' value={searchQuery} type='text' onChange={handleSearchChange} placeholder={t('ADD_PRODUCTS_DIALOG_TOPBAR_SEARCH_BOX_PLACEHOLDER')} />
                                    {
                                        searchQuery.length === 0 ?
                                            <CiSearch className='add-products-dialog-topbar-search-box-image' /> :
                                            <IoCloseOutline className='add-products-dialog-topbar-search-box-image close' onClick={clearSearchQuery} />
                                    }
                                </div>
                            </div>
                            <div ref={productsStatusBarRef} className="multi-select-dialog-status-bar">
                                {
                                    !hasSelectionMaxLimit() && <PaydinCheckbox
                                        checked={allItemsSelected}
                                        setChecked={setAllItemsSelected}
                                        onClick={selectAllItems}
                                    />
                                }
                                <div className="multi-select-dialog-topbar-selected-items-count">{
                                    hasSelectionMaxLimit() ? (
                                        tempSelectedItems.length > 0 ? t('MULTI_SELECT_DIALOG_ITEMS_SELECTED_WITH_LIMIT', { selected: tempSelectedItems.length, limit: selectMaxLimit }) : t('MULTI_SELECT_DIALOG_SELECT_OUT_OF_LIMIT_INSTRUCTION', { limit: selectMaxLimit, itemName })
                                    ) : t('MULTI_SELECT_DIALOG_SELECTED_ITEMS_COUNT', { count: tempSelectedItems.length })
                                }</div>
                            </div>
                            <div className={isMobile ? "mobile-multi-select-dialog-lower-section" : "multi-select-dialog-lower-section"}>
                                {renderItems()}
                            </div>
                        </div>
                    </div>
                </>}
            />
        </>
    )
}